import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postMethodData } from "../../../ApiMethods/Api";
import { RxPerson } from "react-icons/rx";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import assignicon from "../../../assets/images/assigncontractor.svg";
import ViewReservationDetails from "../ReservationManagement/ViewReservationDetails";
import { toast } from "react-toastify";
import encryptParamID from "../../../utils/encryptParamID";
import { Input } from "react-daisyui";
import { Tooltip } from "react-tooltip";
import {
  setApprovedContractorSelectedRow,
  setAssignContractorSelectedRow
} from "../../../Redux-Toolkit/ContractorSlice";
import { format, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";
import { nanoid } from "nanoid";
import { handleIDInputChange, iDValue } from "../../../utils/helpers";

function safe(variable, type) {
  if (type === "array") {
    if (Array.isArray(variable)) {
      return variable;
    } else {
      console.error(`${variable} is not valid`);
      return [];
    }
  }
}

const AssignContractor = () => {

  const reservationFilters = useSelector((state) => state?.reservation?.reservationFilters);

  const { reservationid } = useParams();
  const { puzipCode } = useParams();
  const { dozipCode } = useParams();
  const { reservationsAssignmentsID } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const encryptId = (id) => encryptParamID(id);

  const approvedContractorTableRef = useRef();
  const assignContractorTableRef = useRef();
  const activeApprovedContractorRowRef = useRef(null);
  const approvedContractorRowRefs = useRef([]);
  const activeAssignContractorRowRef = useRef(null);
  const assignContractorRowRefs = useRef([]);

  const [reservationDetails, setReservationDetails] = useState({});
  console.log("1234", reservationDetails);

  const [miles, setMiles] = useState(40);
  const [contractorID, setContractorID] = useState(0);
  const [approvedContractorsLoading, setApprovedContractorsLoading] = useState(false);
  const [contractorDataLoading, setContractorDataLoading] = useState(false);
  const [contractorSearchListData, setContractorSearchListData] = useState([]);
  const [contractorSearchListData_ResponseStatus, setContractorSearchListData_ResponseStatus,
  ] = useState("");

  const [selectedContractor, setSelectedContractor] = useState("");
  const [assignContractorModal, setAssignContractorModal] = useState(false);
  const [approvedContractorsList, setApprovedContractorsList] = useState([]);
  const [zipcode, setZipcode] = useState(puzipCode || dozipCode || 0);
  const [otherContractorSearchClicked, setOtherContractorSearchClicked] = useState(false);
  const [contractorAssignmentJobSearchListData, setContractorAssignmentJobSearchListData,
  ] = useState([]);

  const [service, setService] = useState("");
  const [actionCode, setActionCode] = useState("");
  const [inactiveflag, setInactiveflag] = useState(-2);
  const [contractorAssignDetails, setContractorAssignDetails] = useState([]);
  const [currentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;

  const currentUserList = contractorSearchListData || [];
  const currentApprovedContractorsList = approvedContractorsList || [];

  const selectedApprovedContractorTableRow = useSelector(
    (state) => state?.contractor?.approvedContractorSelectedTableRow
  );

  const selectedAssignedContractorTableRow = useSelector(
    (state) => state?.contractor?.assignContractorSelectedTableRow
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    approvedContractorsSearchList(
      "/ResApprovedContractorSearch",
      {
        reservationid,
        reservationassignmentsid: Number(reservationsAssignmentsID),
        zipcode: puzipCode || dozipCode,
        miles: 40,
        vehsize: "",
        language: "",
        vehtype: "",
        certified: "",
        status: "",
      },
      (response) => {
        if (!response?.data?.status) {
          setApprovedContractorsList([]);
        } else if (response?.data?.status) {
          setApprovedContractorsList(response?.data?.data);
        }
      }
    );

    reservationSearchList(
      "/ReservationSearch",
      Number(reservationid),
      (response) => {

        if (!response?.data?.status) {
          setReservationDetails([]);
        } else if (response?.data?.status) {
          setReservationDetails(response?.data?.data[0]);
          setService(response?.data?.data[0]?.rsvsvCode);
          setActionCode(response?.data?.data[0]?.rsvaccode);
          setInactiveflag(response?.data?.data[0]?.inactiveflag);
        }
      }
    );

    /*replace this one with new job api */
    getContractors(
      "/ContractorAssignmentJobStatus",
      {
        reservationsAssignmentsID: Number(reservationsAssignmentsID),
      },
      (response) => {

        if (!response?.data?.status) {
          setContractorAssignmentJobSearchListData([]);
        } else if (response?.data?.status) {
          setContractorAssignmentJobSearchListData(response?.data?.data);
        }
      }
    );
  }, []);

  useEffect(() => {
    /**
     * Scroll to the saved index if it exists
     */
    if (
      selectedApprovedContractorTableRow !== null &&
      approvedContractorTableRef.current
    ) {
      const td = document.getElementById(selectedApprovedContractorTableRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [approvedContractorsList]);

  useEffect(() => {
    /**
     * Scroll to the saved index if it exists
     */
    if (
      selectedAssignedContractorTableRow !== null &&
      assignContractorTableRef.current
    ) {
      const td = document.getElementById(selectedAssignedContractorTableRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [contractorSearchListData]);

  /**
   * Function to handle the click event of the other contractors search button
   */

  const otherContractorSearchClickHandler = () => {
    setOtherContractorSearchClicked(true);

    getContractors(
      "/ResConAvlSearch",
      {
        reservationid: Number(reservationid),
        reservationassignmentsid: Number(reservationsAssignmentsID),
        ...(selectedOption === "milesAndZipCode" && { zipCode: zipcode }),
        ...(selectedOption === "milesAndZipCode" && { miles: Number(miles) }),
        ...(selectedOption === "contractorID" && {
          contractorID: Number(contractorID),
        }),
      },
      (response) => {

        if (!response?.data?.status) {
          setContractorSearchListData([]);
          setContractorSearchListData_ResponseStatus(response?.data?.statusMessage);
        } else if (response?.data?.status) {
          setContractorSearchListData(response?.data?.data);
          setContractorSearchListData_ResponseStatus(response?.data?.statusMessage);
        }
      }
    );
    // }

    getContractors(
      "/ContractorAssignmentJobStatus",
      {
        reservationsAssignmentsID: Number(reservationsAssignmentsID),
      },
      (response) => {

        if (!response?.data?.status) {
          setContractorAssignmentJobSearchListData([]);
        } else if (response?.data?.status) {
          setContractorAssignmentJobSearchListData(response?.data?.data);
        }
      }
    );
  };

  /**
   * function to get the list of approved contractors list
   * @param {string} url
   * @param {object} body
   */

  const approvedContractorsSearchList = async (url, body, callback) => {
    try {
      setApprovedContractorsLoading(true);
      const response = await postMethodData(url, body);
      setApprovedContractorsLoading(false);
      callback(response);
    } catch (error) {
      setApprovedContractorsLoading(false);
      setApprovedContractorsLoading([]);
    }
  };

  /**
   * function to get the list of contractors found by backend for an assignment
   * @param {string} url
   * @param {object} body
   */

  const getContractors = async (url, body, callback) => {
    try {
      setContractorDataLoading(true);
      const response = await postMethodData(url, body);
      setContractorDataLoading(false);
      callback(response);
    } catch (error) {
      console.error("occured", error);
      setContractorDataLoading(false);
      setContractorSearchListData([]);
    }
  };

  /**
   * fuction to get the reservation details to show at top of page
   * @param {string} url
   * @param {number} id
   * @returns reservations details for an id passed
   */

  const reservationSearchList = async (url, id, callback) => {
    const body = {
      reservationid: id,
      customerName: reservationFilters?.customerName || "",
      claimantname: reservationFilters?.claimantName || "",
      contractor: reservationFilters?.contractor || "",
      serviceName: reservationFilters?.serviceName || "",
      reservationDate: reservationFilters?.reservationDate || "",
      claimEmployerCity: reservationFilters?.claimEmployerCity || "",
      inactiveflag: reservationFilters?.inactiveflag || -2,
      page: reservationFilters?.page || 0,
      limit: reservationFilters?.limit || 0,
    };
    try {
      setContractorDataLoading(true);
      const response = await postMethodData(url, body);
      setContractorDataLoading(false);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setContractorDataLoading(false);
      setReservationDetails({});
    }
  };

  /**
   * function to assign a contractor with comtractor id and based on type passed (forced or optional)
   * @param {number} contractorid
   * @param {number} assignType
   */

  const assignContractor = async (contractorid, assignType) => {
    const body = createRequestBody(contractorid, assignType);

    try {
      const response = await postMethodData("/ResAssignContractor", body);
      openAssignContractorModal();

      await updateReservationDetails();
      await fetchContractorSearchListData();
      await fetchContractorAssignmentJobStatus();

      displaySuccessToast(response?.data?.data[0]?.result);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const createRequestBody = (contractorid, assignType) => ({
    assignType: assignType,
    reservationid: getReservationId(),
    reservationassignmentid: getReservationAssignmentId(),
    contractorid: getContractorId(contractorid),
    contractorname: getContractorDetail("contractorname"),
    company: getContractorDetail("company"),
    cellPhone: getContractorDetail("cellPhone"),
    contycode: getContractorDetail("contycode"),
    conctcode: getContractorDetail("conctcode"),
    conpccode: getContractorDetail("conpccode"),
    gender: getContractorDetail("gender"),
    city: getContractorDetail("city"),
    statecode: getContractorDetail("statecode"),
    zipcode: getContractorDetail("zipcode"),
    miles: getContractorMiles(),
    cstatus: getContractorDetail("zipcode"), // Assuming cstatus is based on zipcode
    constcode: getContractorDetail("constcode"),
    ratePerMiles: getContractorRatePerMiles(),
    cost: getContractorDetail("cost"),
  });

  const getReservationId = () => Number(reservationid) || 0;

  const getReservationAssignmentId = () =>
    Number(reservationsAssignmentsID) || 0;

  const getContractorId = (contractorid) => Number(contractorid) || 0;

  const getContractorDetail = (detail) =>
    contractorAssignDetails?.[detail] ?? "";

  const getContractorMiles = () => Number(contractorAssignDetails?.miles) || 0;

  const getContractorRatePerMiles = () =>
    Number(contractorAssignDetails?.ratePerMiles) || 0;

  const updateReservationDetails = async () => {
    await reservationSearchList(
      "/ReservationSearch",
      Number(reservationid),
      handleReservationSearchResponse
    );
  };

  const handleReservationSearchResponse = (response) => {
    const data = response?.data?.data;
    setReservationDetails(data?.length ? data[0] : {});
  };

  const fetchContractorSearchListData = async () => {
    await getContractors(
      "/ResConAvlSearch",
      {
        reservationid: Number(reservationid),
        zipCode: zipcode,
        reservationassignmentsid: Number(reservationsAssignmentsID),
        miles: Number(miles),
        contractorID: Number(contractorID),
      },
      (response) => {

        if (!response?.data?.status) {
          setContractorSearchListData([]);
          setContractorSearchListData_ResponseStatus(response?.data?.statusMessage);
        } else if (response?.data?.status) {
          setContractorSearchListData(response?.data?.data);
          setContractorSearchListData_ResponseStatus(response?.data?.statusMessage);
        }
      }
    );
  };

  const fetchContractorAssignmentJobStatus = async () => {
    await getContractors(
      "/ContractorAssignmentJobStatus",
      {
        reservationsAssignmentsID: Number(reservationsAssignmentsID),
      },
      (response) => {
        if (!response?.data?.status) {
          setContractorAssignmentJobSearchListData([]);
        } else if (response?.data?.status) {
          setContractorAssignmentJobSearchListData(response?.data?.data);
        }
      }
    );
  };

  const displaySuccessToast = (result) => {
    toast.success(`Contractor ${result}`, {
      position: "top-right",
    });
  };

  const openAssignContractorModal = () => {
    setAssignContractorModal(!assignContractorModal);
  }; //to be removed later.. only for dummy purpose

  // Create a copy of the array and sort it
  const sortedContractorAssignmentJobSearchListData = [
    ...contractorAssignmentJobSearchListData,
  ].sort((a, b) => {
    const order = [1, 0, null, -1];
    return order.indexOf(a.jobStatus) - order.indexOf(b.jobStatus);
  });

  /**
   * function to render table headers based on service type
   * @param {string} tablename
   * @returns table header rows based on service type
   */

  const renderTableHeaders = (tablename) => {
    if (service === "TRNSLT") {
      return (
        <tr>
          <th></th>
          <th>Contractor ID</th>
          <th>Contractor</th>
          <th>Contact</th>
          <th>City</th>
          <th>Zip Code</th>
          <th>Dead Miles</th>
          <th>Cost per Hour</th>
          <th>Total Cost</th>
          {tablename === "system-proposed" ? <th>Job Status</th> : null}
          <th>Action</th>
        </tr>
      );
    } else {
      return (
        <tr>
          <th></th>
          <th>Contractor ID</th>
          <th>Contractor</th>
          <th>Contact</th>
          <th>City</th>
          <th>Zip Code</th>
          <th>Dead Miles</th>
          <th>Total Miles</th>
          <th>Cost per Mile</th>
          <th>Total Cost</th>
          {tablename === "system-proposed" ? <th>Job Status</th> : null}
          <th>Action</th>
        </tr>
      );
    }
  };

  const getJobStatusTooltip = (jobStatus) => {
    switch (jobStatus) {
      case 0:
        return "Notification sent to contractor";
      case -1:
        return "Job request rejected by contractor";
      case 1:
        return "Assignment accepted by contractor";
      case -2:
        return "Withdrawn by Cron Job";
      default:
        return "";
    }
  };

  const isActiveRow = (data) =>
    selectedAssignedContractorTableRow === data?.contractorId
      ? "activeTableRow"
      : "";

  const isAssignmentCancelled = (data) =>
    data?.inactiveflag === -1 ? "assignmentCancelledRow" : "";

  const jobStatusClass = (data) => {
    switch (data?.jobStatus) {
      case 0:
        return "notificationSentRow";
      case -1:
        return "assignmentCancelledRow";
      case 1:
        return "JobAcceptedRow";
      case -2:
        return "withdrawalByCronJobRow";
      default:
        return "";
    }
  };

  const jobStatusText = (data) => {
    switch (data?.jobStatus) {
      case 0:
        return "Notification Sent";
      case -1:
        return "Request Rejected";
      case 1:
        return "Job Accepted";
      case -2:
        return "Job Withdrawn";
      default:
        return "";
    }
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [selectedOption, setSelectedOption] = useState("milesAndZipCode");

  const otherContractorSearchResetHandler = () => {
    setOtherContractorSearchClicked(false);
    setContractorID(0);
  }

  return (
    <>
      <ViewReservationDetails parentComponent={"assignContractor"} />

      {/*---------------------------- //////////  Approved Contractors List  //////////  ----------------------- */}

      <div className=" p-6 flex justify-start">
        <div>
          <p className=" text-xl font-semibold">Approved Contractors </p>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table
            ref={approvedContractorTableRef}
            id="approvedContractorTableScroll"
            className="table table-zebra w-full"
          >
            <thead>{renderTableHeaders("approved-contractor")}</thead>

            <tbody className="text-sm relative">
              {approvedContractorsLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}
              {safe(
                !approvedContractorsLoading &&
                  approvedContractorsList?.length === 0
                  ? currentApprovedContractorsList
                  : approvedContractorsList,
                "array"
              )?.map((data, index) => (
                <tr
                  key={nanoid()}
                  id={`${data.contractorid}`}
                  ref={(ele) => {
                    approvedContractorRowRefs.current[index] = ele;
                    if (index === selectedApprovedContractorTableRow) {
                      activeApprovedContractorRowRef.current = ele;
                    }
                  }}
                  className={`${selectedApprovedContractorTableRow === data.contractorid
                    ? "activeTableRow"
                    : ""
                    } ${data?.inactiveflag === -1 ? "assignmentCancelledRow" : ""
                    } ${(currentPage - 1) * ITEMS_PER_PAGE + index + 1}`}
                  onClick={() => {
                    dispatch(
                      setApprovedContractorSelectedRow(data?.contractorid)
                    );
                    /*
                     *on clicking table row here will clear sected table rao in assoigncontractor table
                     */
                    dispatch(setAssignContractorSelectedRow(null));
                  }}
                >
                  <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>

                  <td>{data?.contractorid}</td>

                  <td>
                    <div className="flex items-center space-x-3">
                      <div>
                        <span
                          className="tooltip text-left"
                          data-tip="View Details"
                        >
                          <div
                            className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                          >
                            <button
                              className=" text-left capitalize"
                              onClick={() => {
                                if (data?.contractorname !== undefined) {
                                  !data?.isDelete &&
                                    navigate(
                                      `/contractor-management/contractor-details/${encryptId(
                                        data?.contractorid
                                      )}`
                                    );
                                }
                              }}
                            >
                              {data?.contractorname ?? "N/A"}
                              <div>({data?.company || "N/A"})</div>
                            </button>
                          </div>
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>{data?.cellPhone || "N/A"}</td>

                  <td>{data?.city ?? "N/A"}</td>
                  <td>{data?.zipcode ?? "N/A"}</td>
                  <td>{data?.miles ?? "N/A"}</td>
                  <td>{miles}</td>
                  <td>$ {data?.ratePerMiles || 0}</td>
                  <td>$ {data?.cost || "N/A"}</td>

                  <td className="text-base">
                    <div className="flex items-center space-x-4">
                      {actionCode !== "ResCancel" ? (
                        <button title="">
                          <span className="flex items-center space-x-4">
                            <label
                              className="tooltip text-left"
                              htmlFor="confirm-modal"
                              data-tip="Assign"
                            >
                              <Link
                                className="cursor-pointer"
                                onClick={() => {
                                  setContractorAssignDetails(data);
                                  setSelectedContractor(data?.contractorid);
                                  openAssignContractorModal();
                                }}
                              >
                                <img
                                  src={assignicon}
                                  alt="Assign"
                                  className="cursor-pointer w-8 h-8 text-[#7ac46b]"
                                />
                              </Link>
                            </label>
                          </span>
                        </button>
                      ) : (
                        <span
                          className="text-[red] tooltip cursor-pointer"
                          data-tip="Reservation is cancelled"
                        >
                          Cannot assign the contractor.
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}

              {!approvedContractorsLoading &&
                approvedContractorsList?.length === 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">
                        No Approved Contractors Found
                      </p>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>

          {/* status change model start */}
          <input
            type="checkbox"
            checked={assignContractorModal}
            id="confirm-modal"
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box relative">

              <button
                type="button" // Use type="button" to prevent form submission
                onClick={() => {
                  openAssignContractorModal();
                }}
                className="btn btn-sm btn-circle absolute right-2 top-2"
                aria-label="Close" // Optional: add aria-label for accessibility
              >
                ✕
              </button>

              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Assign this contractor ?
              </h3>
              <div className="modal-action justify-center">
                <button
                  className="btn btn-md bg-[red] hover:bg-[red] text-[#fff] text-sm hover:text-darkgreen ml-4"
                  onClick={() => {
                    assignContractor(selectedContractor, "Forced");
                  }}
                >
                  Force Assign
                </button>
                <button
                  className="btn btn-md bg-[green] hover:bg-[green] text-[#fff] text-sm hover:text-darkgreen ml-4"
                  onClick={() => {
                    assignContractor(selectedContractor, "Request");
                  }}
                >
                  Optional Assign
                </button>
                <button
                  onClick={() => openAssignContractorModal()}
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:text-[#fff]"
                >
                  Don't Assign
                </button>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
      </div>

      {/*-----------------contractor search form filters----------------- */}

      {(actionCode !== "ResCancel" || inactiveflag !== -1) && (
        <>
          <div className=" p-6 flex justify-start">
            <div>
              <p className=" text-xl font-semibold">Find Other Contractors</p>
            </div>
          </div>
          <div className="card bg-base-100 shadow-md p-6">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4 ">
                {/*------ radio buttons --------- */}
                <div className="grid lg:grid-cols-2 md-grid-cols-2 sm-grid-cols-2 flex-wrap">
                  <label className="block text-base font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                    <input
                      type="radio"
                      name="searchOption"
                      value="milesAndZipCode"
                      checked={selectedOption === "milesAndZipCode"}
                      onChange={handleRadioChange}
                      className="mr-2" />
                    <span>By Miles and ZipCode</span>
                  </label>
                  <label className="block text-base font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                    <input
                      type="radio"
                      name="searchOption"
                      value="contractorID"
                      checked={selectedOption === "contractorID"}
                      onChange={handleRadioChange}
                      className="mr-2" />
                    <span>By Contractor ID</span>
                  </label>
                </div>
                {/*-------- search fields ---------- */}
                <div className="grid lg:grid-cols-2 md-grid-cols-2 gap-4">
                  {/* {selectedOption === "milesAndZipCode" && ( */}
                  <div className="flex flex-wrap gap-8">
                    <div>
                      <label
                        htmlFor="miles"
                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                      >
                        Select Miles
                      </label>
                      <Input
                        name="miles"
                        id="miles"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length <= 10 && /^\d*$/.test(value)) {
                            setMiles(value);
                          }
                        }}
                        disabled={selectedOption !== "milesAndZipCode"}
                        value={miles}
                        placeholder="Miles"
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="pickupzipcode"
                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                      >
                        {service === "TRNSLT" || service === "IN" ? (
                          <span>Reservation Zipcode</span>
                        ) : (
                          <span>Reservation Pickup Zipcode</span>
                        )}
                      </label>
                      <Input
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length <= 10 && /^\d*$/.test(value)) {
                            setZipcode(value);
                          }
                        }}
                        value={zipcode}
                        placeholder="Zip Code"
                        id="pickupzipcode"
                        type="text"
                        autoComplete="off"
                        disabled={selectedOption !== "milesAndZipCode"}
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="contractorid"
                      className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                    >
                      Contractor ID
                    </label>
                    <Input
                      name="contractorid"
                      id="contractorid"
                      type="text"
                      onChange={(e) => handleIDInputChange(e, setContractorID)}
                      value={iDValue(contractorID)}
                      placeholder="Contractor ID"
                      disabled={selectedOption !== "contractorID"}
                      className="input focus:outline-0 max-w-fit text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                    />
                  </div>
                  {/* )} */}
                </div>
              </div>

              {/* ---------- search and reset button ---------- */}
              <div className="grid lg:grid-cols-3 md-grid-cols-3 gap-4">
                <div className="lg:mt-3 mt-4 flex">
                  <button
                    className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000]"
                    onClick={() => {
                      otherContractorSearchClickHandler();
                    }}
                  >
                    <MdOutlineSearch className="mr-1" />
                    Search
                  </button>
                  {otherContractorSearchClicked && (
                    <button
                      className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-[30px]"
                      onClick={() => otherContractorSearchResetHandler()}
                    >
                      <MdOutlineRefresh className="mr-1" />
                      Reset
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/*----------------------------contractors list ----------------------- */}

            {otherContractorSearchClicked && (
              <>
                <div className=" p-6 flex justify-between">
                  <div>
                    <p className=" text-xl font-semibold">
                      Other Contractors List
                    </p>
                  </div>
                </div>
                <div className="card bg-base-100 shadow-md p-6">
                  <div className="overflow-x-auto w-full">
                    <table
                      ref={assignContractorTableRef}
                      id="assignContractorTableScroll"
                      className="table table-zebra w-full"
                    >
                      {renderTableHeaders("other-contractor")}

                      <tbody className="text-sm relative">
                        {contractorDataLoading &&
                          Array.from({ length: 5 }).map((_, index) => (
                            <tr key={nanoid()}>
                              <td
                                colSpan={13}
                                className="animate-pulse py-6"
                              ></td>
                            </tr>
                          ))}

                        {(!contractorDataLoading &&
                          contractorSearchListData?.length === 0
                          ? contractorSearchListData
                          : currentUserList
                        )?.map((data, index) => (
                          <tr
                            key={nanoid()}
                            id={`${data.contractorid}`}
                            ref={(ele) => {
                              assignContractorRowRefs.current[index] = ele;
                              if (
                                index === selectedAssignedContractorTableRow
                              ) {
                                activeAssignContractorRowRef.current = ele;
                              }
                            }}
                            className={`${selectedAssignedContractorTableRow ===
                              data.contractorid
                              ? "activeTableRow"
                              : ""
                              } ${data?.inactiveflag === -1
                                ? "assignmentCancelledRow"
                                : ""
                              } ${(currentPage - 1) * ITEMS_PER_PAGE + index + 1}`}
                            onClick={() => {
                              dispatch(
                                setAssignContractorSelectedRow(
                                  data?.contractorid
                                )
                              );
                              /*
                               *on clicking table row here will clear sected table row of approved contractor table
                               */
                              dispatch(setApprovedContractorSelectedRow(null));
                            }}
                          >
                            <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>

                            <td>{data?.contractorid}</td>

                            <td>
                              <div className="flex items-center space-x-3">
                                <div>
                                  <span
                                    className="tooltip text-left"
                                    data-tip="View Details"
                                  >
                                    <div
                                      className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                                    >
                                      <button
                                        className="text-left"
                                        onClick={() => {
                                          if (
                                            data?.contractorname !== undefined
                                          ) {
                                            !data?.isDelete &&
                                              navigate(
                                                `/contractor-management/contractor-details/${encryptId(
                                                  data?.contractorid
                                                )}`
                                              );
                                          }
                                        }}
                                      >
                                        {data?.contractorname ?? "N/A"}
                                        <div>({data?.company || "N/A"})</div>
                                      </button>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td>{data?.cellPhone || "N/A"}</td>

                            <td>{data?.city || "N/A"}</td>
                            <td>{data?.zipcode || "N/A"}</td>
                            <td>{data?.miles || 0}</td>
                            <td>{miles}</td>
                            <td>$ {data?.ratePerMiles || 0}</td>
                            <td>$ {data?.cost || "0"}</td>

                            <td className=" text-base">
                              <div className="flex items-center space-x-4">
                                {actionCode !== "ResCancel" ? (
                                  <button title="">
                                    <span className="flex items-center space-x-4">
                                      <label
                                        className="tooltip text-left"
                                        htmlFor="confirm-modal"
                                        data-tip={
                                          data?.cstatus !== "Active"
                                            ? data?.cstatus
                                            : "Assign"
                                        }
                                      >
                                        <Link
                                          className="cursor-pointer"
                                          onClick={() => {
                                            if (data?.cstatus === "Active") {
                                              setContractorAssignDetails(data);
                                              setSelectedContractor(
                                                data?.contractorid
                                              );
                                              openAssignContractorModal();
                                            }
                                          }}
                                        >
                                          <img
                                            src={assignicon}
                                            alt="Assign"
                                            className="cursor-pointer w-8 h-8 text-[#7ac46b]"
                                          />
                                        </Link>
                                      </label>
                                    </span>
                                  </button>
                                ) : (
                                  <span
                                    className="text-[red] tooltip cursor-pointer"
                                    data-tip="Reservation is cancelled"
                                  >
                                    Cannot assign the contractor.
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}

                        {!contractorDataLoading &&
                          contractorSearchListData?.length === 0 && (
                            <tr>
                              <td colSpan={11} className="my-10 text-center">
                                <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                                <p className=" font-semibold text-lg">
                                  {contractorSearchListData_ResponseStatus ||
                                    "No Data Found"}
                                </p>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>

                    {/* status change model start */}
                    <input
                      type="checkbox"
                      checked={assignContractorModal}
                      id="confirm-modal"
                      className="modal-toggle"
                    />
                    <div className="modal">
                      <div className="modal-box relative">
                        <button
                          type="button" // Use type="button" to prevent form submission
                          onClick={() => {
                            openAssignContractorModal();
                          }}
                          className="btn btn-sm btn-circle absolute right-2 top-2"
                          aria-label="Close" // Optional: add aria-label for accessibility
                        >
                          ✕
                        </button>
                        <h3 className="text-lg font-bold text-center">
                          Are you sure you want to Assign this contractor ?
                        </h3>
                        <div className="modal-action justify-center">
                          <button
                            className="btn btn-md bg-[red] hover:bg-[red]  text-[#fff] text-sm ml-4"
                            onClick={() => {
                              assignContractor(selectedContractor, "Forced");
                            }}
                          >
                            Force Assign
                          </button>
                          <button
                            className="btn btn-md bg-[green] hover:bg-[green] text-[#fff] text-sm ml-4"
                            onClick={() => {
                              assignContractor(selectedContractor, "Request");
                            }}
                          >
                            Optional Assign
                          </button>
                          <button
                            onClick={() => openAssignContractorModal()}
                            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:text-[#fff]  "
                          >
                            Don't Assign
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* status change model end */}

                    {/* ----------------------------------react tooltip component */}
                    <Tooltip id="my-tooltip" />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {/*----------------------------ContractorAssignmentJobStatus NEW API contractors list ----------------------- */}

      <>
        <div className=" p-6 flex justify-between">
          <div>
            <p className=" text-xl font-semibold">
              System Proposed Contractors List
            </p>
          </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6">
          <div className="overflow-x-auto w-full">
            <table
              ref={assignContractorTableRef}
              id="assignContractorTableScroll"
              className="table table-zebra w-full"
            >
              {renderTableHeaders("system-proposed")}

              <tbody className="text-sm relative">
                {contractorDataLoading &&
                  Array.from({ length: 5 }).map((_, index) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {!contractorDataLoading &&
                  contractorAssignmentJobSearchListData?.length !== 0 &&
                  sortedContractorAssignmentJobSearchListData?.map(
                    (data, index) => (
                      <tr
                        key={nanoid()}
                        id={`${data.contractorId}`}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={getJobStatusTooltip(
                          data?.jobStatus
                        )}
                        data-tooltip-place="top"
                        ref={(ele) => {
                          assignContractorRowRefs.current[index] = ele;
                          if (index === selectedAssignedContractorTableRow) {
                            activeAssignContractorRowRef.current = ele;
                          }
                        }}
                        className={` ${(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                        ${isActiveRow(data)} ${isAssignmentCancelled(data)} ${jobStatusClass(data)}`}
                        onClick={() => {
                          dispatch(
                            setAssignContractorSelectedRow(data?.contractorId)
                          );
                          /*
                           *on clicking table row here will clear sected table row of approved contractor table
                           */
                          dispatch(setApprovedContractorSelectedRow(null));
                        }}
                      >
                        <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>

                        <td>{data?.contractorId}</td>

                        <td>
                          <div className="flex items-center space-x-3">
                            <div>
                              <span
                                className="tooltip text-left"
                                data-tip="View Details"
                              >
                                <div
                                  className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                                >
                                  <button
                                    className="text-left capitalize"
                                    onClick={() => {
                                      if (data?.contractorName !== undefined) {
                                        !data?.isDelete &&
                                          navigate(
                                            `/contractor-management/contractor-details/${encryptId(
                                              data?.contractorId
                                            )}`
                                          );
                                      }
                                    }}
                                  >
                                    {data?.contractorName ?? "N/A"}
                                    <div>({data?.company || "N/A"})</div>
                                  </button>
                                </div>
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>{data?.cellPhone || "N/A"}</td>

                        <td>{data?.city || "N/A"}</td>
                        <td>{data?.zipCode || "N/A"}</td>
                        <td>{data?.miles || 0}</td>
                        <td>{miles || 0}</td>
                        <td>$ {data?.ratePerMiles || 0}</td>
                        <td>$ {data?.cost || "0"}</td>
                        <td>
                          <span
                            className={`${data?.jobStatus === 0 &&
                              data?.notificationDateTime
                              ? "tooltip text-left"
                              : ""
                              }`}
                            data-tip={`${data?.jobStatus === 0 &&
                              data?.notificationDateTime &&
                              format(
                                parseISO(data?.notificationDateTime),
                                "PPpp",
                                { locale: enUS }
                              )
                              }`}
                          >
                            {jobStatusText(data)}
                          </span>
                        </td>

                        <td className=" text-base">
                          <div className="flex items-center space-x-4">
                            {data?.jobStatus !== 0 &&
                              data?.jobStatus !== -1 &&
                              data?.jobStatus !== 1 &&
                              data?.jobStatus !== -2 && (
                                <>
                                  {actionCode !== "ResCancel" ? (
                                    <button title="">
                                      <span className="flex items-center space-x-4">
                                        <label
                                          className="tooltip text-left"
                                          htmlFor="confirm-modal"
                                          data-tip="Assign"
                                        >
                                          <Link
                                            className="cursor-pointer"
                                            onClick={() => {
                                              setContractorAssignDetails(data);
                                              setSelectedContractor(
                                                data?.contractorId
                                              );
                                              openAssignContractorModal();
                                            }}
                                          >
                                            <img
                                              src={assignicon}
                                              alt="Assign"
                                              className="cursor-pointer w-8 h-8 text-[#7ac46b]"
                                            />
                                          </Link>
                                        </label>
                                      </span>
                                    </button>
                                  ) : (
                                    <span
                                      className="text-[red] tooltip cursor-pointer"
                                      data-tip="Reservation is cancelled"
                                    >
                                      Cannot assign the contractor.
                                    </span>
                                  )}
                                </>
                              )}
                          </div>
                        </td>
                      </tr>
                    )
                  )}

                {!contractorDataLoading &&
                  contractorAssignmentJobSearchListData?.length === 0 && (
                    <tr>
                      <td colSpan={11} className="my-10 text-center">
                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                        <p className=" font-semibold text-lg">
                          No Contractor Found
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>

            {/* status change model start */}
            <input
              type="checkbox"
              checked={assignContractorModal}
              id="confirm-modal"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box relative">
                <button
                  type="button" // Use type="button" to prevent form submission
                  onClick={() => {
                    openAssignContractorModal();
                  }}
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                  aria-label="Close" // Optional: add aria-label for accessibility
                >
                  ✕
                </button>
                <h3 className="text-lg font-bold text-center">
                  Are you sure you want to Assign this contractor ?
                </h3>
                <div className="modal-action justify-center">
                  <button
                    className="btn btn-md bg-[red] hover:bg-[red]  text-[#fff] text-sm ml-4"
                    onClick={() => {
                      assignContractor(selectedContractor, "Forced");
                    }}
                  >
                    Force Assign
                  </button>
                  <button
                    className="btn btn-md bg-[green] hover:bg-[green] text-[#fff] text-sm ml-4"
                    onClick={() => {
                      assignContractor(selectedContractor, "Request");
                    }}
                  >
                    Optional Assign
                  </button>
                  <button
                    onClick={() => openAssignContractorModal()}
                    className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:text-[#fff]  "
                  >
                    Don't Assign
                  </button>
                </div>
              </div>
            </div>
            {/* status change model end */}

            {/* ----------------------------------react tooltip component */}
            <Tooltip id="my-tooltip" />
          </div>
        </div>
      </>
    </>
  );
};

export default AssignContractor;
