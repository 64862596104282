import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";

const ReactPagination = ({
  forcePage,
  pageCount,
  setPageCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  containerClassName,
  activeClassName,
  pageChangeOptions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = forcePage;

  const handlePageChange = async ({ selected }) => {
    // using template api hit for dynamic api search for any component

    pageChangeOptions?.endPointFunction(
      `${pageChangeOptions?.endpoint}`,
      {
        ...pageChangeOptions?.componentFilter,
        page: Number(selected) + 1,
        limit: pageChangeOptions?.itemsPerPage,
      },
      (response) => {
        if (response?.data?.data?.length === 0) {
          //to update this qwith statua as true or false with boolean value from response once himanshu updates response

          dispatch(pageChangeOptions?.dispatchAction([]));
          setPageCount(0);
        } else {
          dispatch(pageChangeOptions?.dispatchAction(response?.data?.data));
          setPageCount(
            Math.ceil(
              response?.data?.totalData / pageChangeOptions?.itemsPerPage
            )
          );
        }
      }
    );

    const element = document.getElementById("tablescroll");

    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 150;
      window.scrollTo({ top: y, behavior: "smooth" });
    }

    if (Number(selected) + 1 !== 1) {
      navigate(`?page=${Number(selected) + 1}`);
    } else {
      navigate("");
    }
  };

  return (
    <ReactPaginate
      forcePage={currentPage > pageCount ? pageCount - 1 : currentPage}
      pageCount={pageCount > 0 ? pageCount : 1}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={handlePageChange}
      containerClassName={containerClassName}
      activeClassName={activeClassName}
    />
  );
};

ReactPagination.propTypes = {
  forcePage: PropTypes.number,
  pageCount: PropTypes.number,
  setPageCount: PropTypes.func,
  pageRangeDisplayed: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  containerClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  pageChangeOptions: PropTypes.shape({
    endPointFunction: PropTypes.func,
    endpoint: PropTypes.string,
    componentFilter: PropTypes.object,
    itemsPerPage: PropTypes.number,
    dispatchAction: PropTypes.func,
  }),
};

export default ReactPagination;
