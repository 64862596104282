import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setmetricsFilters, updatemetricsFilters } from "../../../../Redux-Toolkit/MatricsSlice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import InputSearchFilterDropDown from "../../../../Common/ui/inputSearchFilterDropDown";

const MetricsManagementFilter = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const metricFilters = useSelector((state) => state?.metrics?.metricsFilter) || [];

  const initializeState = (filter) => ({
    contractorID: filter?.contractorID || 0,
    customerID: filter?.customerID || 0,
    claimNumber: filter?.claimNumber || "",
    reservationsAssignmentsID: filter?.reservationAssignmentID || 0,
    reservationID: filter?.reservationID || 0,
    dateFrom: filter?.dateFrom || "",
    dateTo: filter?.dateTo || "",
    isMetricsEntered: filter?.isMetricsEntered || -2,
    page: filter?.page || 1,
  });

  const initialState = initializeState(metricFilters);
  const [contractorID, setContractorID] = useState(initialState.contractorID);
  const [customerID, setCustomerID] = useState(initialState.customerID);
  const [selectedCustomerName, setSelectedCustomerName] = useState(initialState.customerName);
  const [selectedContractorName, setSelectedContractorName] = useState(initialState.contractorName);
  const [claimnumber, setClaimnumber] = useState(initialState.claimNumber);
  const [reservationsAssignmentsID, setReservationsAssignmentsID] = useState(initialState.reservationsAssignmentsID);
  const [reservationID, setReservationID] = useState(initialState.reservationID);
  const [dateFrom, setDateFrom] = useState(initialState.dateFrom);
  const [dateTo, setDateTo] = useState(initialState.dateTo);
  const [isMetricsEntered, setIsMetricsEntered] = useState(
    initialState.isMetricsEntered
  );
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    updateStateFromFilter(metricFilters);
  }, [metricFilters]);

  const updateStateFromFilter = (filter) => {
    setReservationID(filter?.reservationID);
    setClaimnumber(filter?.claimNumber);
    setContractorID(filter?.contractorID);
    setCustomerID(filter?.customerID);
    setSelectedCustomerName(filter?.customerName);
    setSelectedContractorName(filter?.contractorName);
    setReservationsAssignmentsID(filter?.reservationAssignmentID);
    setDateFrom(filter?.dateFrom);
    setDateTo(filter?.dateTo);
    setIsMetricsEntered(filter?.isMetricsEntered);
    setPage(filter?.page);
  };

  /**
   * Reset the filter values
   */

  const handleReset = () => {
    resetState();
    dispatch(setmetricsFilters(createFilterData()));
    navigate("/metrics-management");
  };

  const resetState = () => {
    setContractorID(0);
    setCustomerID(0);
    setSelectedCustomerName("");
    setSelectedContractorName("");
    setClaimnumber("");
    setDateFrom("");
    setDateTo("");
    setReservationsAssignmentsID(0);
    setReservationID(0);
    setIsMetricsEntered(-2);
  };

  const createFilterData = () => ({
    contractorID: 0,
    customerID: 0,
    claimNumber: "",
    reservationsAssignmentsID: 0,
    reservationID: 0,
    dateFrom: "",
    dateTo: "",
    isMetricsEntered: -2,
    page: page,
    limit: 20,
  });

  return (
    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const data = {
            ...(reservationID &&
              !isNaN(Number(reservationID)) && {
              reservationID: Number(reservationID),
            }),
            ...(reservationsAssignmentsID &&
              !isNaN(Number(reservationsAssignmentsID)) && {
              reservationsAssignmentsID: Number(reservationsAssignmentsID),
            }),
            ...(customerID &&
              !isNaN(Number(customerID)) && { customerID: Number(customerID) }),

            ...(contractorID &&
              !isNaN(Number(contractorID)) && {
              contractorID: Number(contractorID),
            }),
            ...(claimnumber && claimnumber.trim() !== "" && { claimnumber }),
            ...(dateFrom && dateFrom.trim() !== "" && { dateFrom }),

            ...(dateTo && dateTo.trim() !== "" && { dateTo }),
            ...(isMetricsEntered &&
              !isNaN(Number(isMetricsEntered)) && {
              isMetricsEntered: Number(isMetricsEntered),
            }),
            page: 1,
            limit: 20,
          };

          dispatch(setmetricsFilters(data));
          dispatch(updatemetricsFilters({
            customerName: selectedCustomerName,
            contractorName: selectedContractorName
          }));
          navigate(`?page=1`);
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          {/* <div>
            <label
              htmlFor="assignmentid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Assignment ID
            </label>
            <input
              name="assignmentid"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setReservationsAssignmentsID(e.target.value);
                }
              }}
              value={
                reservationsAssignmentsID === 0 ? "" : reservationsAssignmentsID
              }
              placeholder="Assignment ID"
              id="assignmentid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div> */}

          <div>
            <label
              htmlFor="reservationID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Assignment ID/ Reservation ID
            </label>
            <input
              name="reservationID"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setReservationID(e.target.value);
                }
              }}
              value={reservationID === 0 ? "" : reservationID}
              placeholder="Assignment ID/ Reservation ID"
              id="reservationID"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>

          <div>
            <label
              htmlFor="claimNumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim Number
            </label>
            <input
              name="claimNumber"
              onChange={(e) => {
                setClaimnumber(e.target.value);
              }}
              value={claimnumber}
              placeholder="Claim Number"
              id="claimNumber"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>

          <div>
            <label
              htmlFor="dateFrom"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Date From
            </label>
            <Input
              name="dateFrom"
              placeholder=""
              onChange={(e) => {
                const dateValue = e.target.value;
                if (dateValue) {
                  setDateFrom(format(new Date(e.target.value), "yyyy-MM-dd"));
                } else {
                  setDateFrom(""); //reset date value if empty
                }
              }}
              value={dateFrom}
              max={new Date().toISOString().split("T")[0]}
              id="dateFrom"
              type="date"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="dateTo"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Date To
            </label>
            <Input
              name="dateTo"
              placeholder=""
              onChange={(e) => {
                const dateValue = e.target.value;
                if (dateValue) {
                  setDateTo(format(new Date(e.target.value), "yyyy-MM-dd"));
                } else {
                  setDateTo(""); //reset date value if empty
                }
              }}
              value={dateTo}
              max={new Date().toISOString().split("T")[0]}
              id="dateTo"
              type="date"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              className={"customer-search"}
              labelName={"Customer"}
              url="/AllCustomer"
              payloadName={"customerName"}
              data={[]}
              setSearchList={(selectedOption) => {
                setCustomerID(selectedOption?.customerID);
                setSelectedCustomerName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedCustomerName}
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              className={"contractor-search"}
              labelName={"Contractor"}
              url="/AllContractor"
              payloadName={"contractorName"}
              data={[]}
              setSearchList={(selectedOption) => {
                setContractorID(selectedOption?.contractorID);
                setSelectedContractorName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedContractorName}
            />
          </div>

          <div>
            <label
              htmlFor="metricEntered"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Metrics Entered from Intime
            </label>
            <select
              id="metricEntered"
              onChange={(e) => setIsMetricsEntered(e.target.value)}
              name="metricsEntered"
              value={isMetricsEntered}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={"-2"}>All</option>
              <option value={"-1"}>Checked</option>
              <option value={"0"}>Unchecked</option>
            </select>
          </div>
        </div>
        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              handleReset();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4">
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default MetricsManagementFilter;
