import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMethodData, postMethodDataWithToken } from "../../../ApiMethods/Api";
import { setSelectedRow, setStaff, setStaffRoles } from "../../../Redux-Toolkit/StaffSlice";
import { RxPerson } from "react-icons/rx";
import { useParams, useSearchParams } from "react-router-dom";
import Filter from "./StaffManagementFilter/StaffManagementFilter";
import { formatDate } from "../../../utils/helpers";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import { FaKey } from "react-icons/fa6";
import ContractorClaimantStaffPasswordReset from "../../../Common/ContractorClaimantStaffPasswordReset";

const StaffManagement = () => {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const searchParamPage = searchParams.get("page");
  const token = useSelector((state) => state?.auth?.Token);
  const userRole = useSelector((state) => state?.auth?.role);

  const { id } = useParams();
  const tableRef = useRef();

  const [staffDataLoading, setStaffDataLoading] = useState(true);
  const staffFilters = useSelector((state) => state?.staff?.staffFilters);
  const staffSearchListData = useSelector((state) => state?.staff?.staff) || [];
  const rowRefs = useRef([]);

  const selectedRow = useSelector((state) => state?.staff?.selectedTableRow);
  const activeRowRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);

  const [pageCount, setPageCount] = useState(0);
  const ITEMS_PER_PAGE = 20;
  const [isPasswordResetVisible, setIsPasswordResetVisible] = useState({
    id: null,
    isVisible: false,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [staffSearchListData]);

  useEffect(() => {
    fetchStaffData(
      "/StaffDetails",
      { ...staffFilters, page: Number(currentPage), limit: ITEMS_PER_PAGE },
      (response) => {
        if (!response?.data?.status) {
          dispatch(setStaff([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setStaff(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
    getStaffRoles("/StaffRoles");
  }, [staffFilters, currentPage]);

  const fetchStaffData = async (URL, data, callback) => {
    setStaffDataLoading(true);
    const body = data;
    try {
      const response = await postMethodDataWithToken(URL, body, token);
      setStaffDataLoading(false);
      callback(response); // Assuming data is under data property
    } catch (error) {
      console.error("An error occurred while fetching staff data:", error);
      setStaffDataLoading(false);
      dispatch(setStaff([]));
    }
  };

  const getStaffRoles = async (url) => {
    try {
      const response = await getMethodData(url);
      dispatch(setStaffRoles(response?.data?.data));
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
      dispatch(setStaffRoles([]));
    }
  };

  const pageChangeOptions = {
    endPointFunction: fetchStaffData,
    endpoint: "/StaffDetails",
    componentFilter: staffFilters,
    dispatchAction: setStaff,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll",
  };

  return (

    <>
      <div className="flex justify-between">
        <div className="mb-4">
          <p className="text-2xl font-semibold">Staff Management</p>
        </div>
      </div>
      <Filter league={id} />
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table
            ref={tableRef}
            id="tablescroll"
            className="table table-zebra w-full"
          >
            <thead>
              <tr>
                <th></th>
                <th>User ID</th>
                <th style={{ whiteSpace: "normal" }}>User Name</th>
                <th style={{ whiteSpace: "normal" }}>Role</th>
                <th style={{ whiteSpace: "normal" }}>Last login</th>
                <th style={{ whiteSpace: "normal" }}>Status</th>
                <th style={{ whiteSpace: "normal" }}>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {staffDataLoading &&
                Array.from({ length: 5 }).map((_) => (
                  <tr key={nanoid()}>
                    <td colSpan={6} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {!staffDataLoading &&
                staffSearchListData?.length !== 0 &&
                staffSearchListData?.map((data, index) => (
                  <tr
                    key={nanoid()}
                    id={data?.userID}
                    ref={(ele) => {
                      rowRefs.current[index] = ele;
                      if (index === selectedRow) {
                        activeRowRef.current = ele;
                      }
                    }}
                    className={
                      data?.userID === selectedRow ? "activeTableRow" : ""
                    }
                    onClick={() => {
                      dispatch(setSelectedRow(data?.userID));
                    }}
                  >
                    <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                    <td>{data?.userID}</td>
                    <td>{data?.userName}</td>
                    <td>{data?.groupName || "N/A"}</td>
                    <td>
                      <div>{formatDate(data?.lastLoginDate)}</div>
                    </td>

                    <td>
                      <label
                        className={`btn capitalize ${data?.inactiveflag === -1
                          ? "bg-[red] hover:bg-[red] "
                          : "bg-[#39ff14] hover:bg-[#39ff14]"
                          }  text-[#000] border-hidden  btn-xs cursor-default`}
                      >
                        {data?.inactiveflag ? "Inactive" : "Active"}
                      </label>
                    </td>
                    <td className=" text-base">
                      <div className="flex items-center space-x-4 flex-wrap">
                        <button title="">
                          <span className="flex flex-wrap">
                            <div className="w-full flex items-center justify-center space-x-4 pb-2 mb-2 border-b border-[lightgray]">
                              {["Administrators"].includes(userRole) && (
                                <>
                                  <button
                                    className="tooltip"
                                    data-tip="Update Password"
                                    name="updatePassword"
                                    onClick={() => {
                                      setIsPasswordResetVisible({
                                        id: data?.userID,
                                        isVisible: true,
                                      });
                                    }}
                                    htmlFor="my-modal-3"
                                  >
                                    <FaKey className="cursor-pointer" />
                                  </button>
                                  {isPasswordResetVisible.isVisible &&
                                    isPasswordResetVisible.id ===
                                    data?.userID ? (
                                    <ContractorClaimantStaffPasswordReset
                                      htmlFor={"my-modal-3"}
                                      id={data?.userID}
                                      onClose={() =>
                                        setIsPasswordResetVisible({
                                          id: null,
                                          isVisible: false,
                                        })
                                      }
                                    />
                                  ) : null}
                                </>
                              )}

                            </div>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              {!staffDataLoading && staffSearchListData?.length === 0 && (
                <tr>
                  <td colSpan={6} className="my-10 text-center">
                    <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Staff Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {staffSearchListData?.length !== 0 && (
            <ReactPagination
              forcePage={currentPage - 1}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              pageChangeOptions={pageChangeOptions}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StaffManagement;
