import React from "react";
import { Input } from "react-daisyui";
import PropTypes from "prop-types";

const AssignmentCost = ({
  assignmentCompleteCost,
  reservationStatus,
  assignmentDetails,
}) => {
  console.log("reservationStatus", typeof(reservationStatus));
  
  return (
    <>
      {assignmentCompleteCost === -1 && (
        <>
          <div className=" p-6 flex justify-start">
            <div>
              <p className=" text-xl font-semibold">Assignment Cost </p>
            </div>
          </div>
          <div
            className=" flex card bg-base-100 shadow-md p-6 mt-2"
            style={{ flexDirection: "row" }}
          >
            {reservationStatus === "Reservation Completed" ? (
              <div className="w-[50%]">
                <label className="grid grid-cols-2 mt-3 items-center">
                  Total Miles
                  <Input
                    name="totalMile"
                    id="totalMile"
                    value={assignmentDetails?.totalMile || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Per Miles
                  <Input
                    name="perMile"
                    id="perMile"
                    value={assignmentDetails?.perMile || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Total Cost
                  <Input
                    name="totalCost"
                    id="totalCost"
                    value={assignmentDetails?.totalCost || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Waiting Time Cost
                  <Input
                    name="waitingTimeCost"
                    id="waitingTimeCost"
                    value={assignmentDetails?.totalCost || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Total Waiting Time
                  <Input
                    name="totalWaitingTime"
                    id="totalWaitingTime"
                    value={assignmentDetails?.totalCost || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Total Waiting Cost
                  <Input
                    name="totalWaitingCost"
                    id="totalWaitingCost"
                    value={assignmentDetails?.totalCost || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Parking Fees
                  <Input
                    name="parkingFee"
                    id="parkingFee"
                    value={assignmentDetails?.totalCost || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>

                <label className="grid grid-cols-2 mt-3 items-center">
                  Other Charges
                  <Input
                    name="otherCharge"
                    id="otherCharge"
                    value={assignmentDetails?.totalCost || "N/A"}
                    readOnly
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>
              </div>
            ) : (
              <div className="text-xl font-semibold m-auto">TBA</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

// Define prop types
AssignmentCost.propTypes = {
  assignmentCompleteCost: PropTypes.number,
  reservationStatus: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool]),
  assignmentDetails: PropTypes.shape({
    totalMile: PropTypes.number,
    perMile: PropTypes.number,
    totalCost: PropTypes.number,
    waitingTimeCost: PropTypes.number,
    totalWaitingTime: PropTypes.number,
    totalWaitingCost: PropTypes.number,
    parkingFee: PropTypes.number,
    otherCharge: PropTypes.number,
  }),
};

export default AssignmentCost;
