import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { MdRemoveRedEye } from "react-icons/md";
import { postMethodData } from "../../../ApiMethods/Api";
import MetricsFilter from "./MetricsManagementFilter/MetricsManagementFilter";
import {
  setmetricsList,
  setSelectedRow,
} from "../../../Redux-Toolkit/MatricsSlice";
import decryptParamID from "../../../utils/decryptParamID";
import encryptParamID from "../../../utils/encryptParamID";
import { formatDate } from "../../../utils/helpers";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const MetricsManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef();

  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const params = decryptParamID(useParams());
  const urlHasParams = Object.keys(params).length > 0;
  const userRole = useSelector((state) => state?.auth?.role);
  const metricList = useSelector((state) => state?.metrics?.metricsList) || [];

  const [metricsListLoading, setMetricsListLoading] = useState(false);
  const rowRefs = useRef([]);
  const [pageCount, setPageCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);
  const ITEMS_PER_PAGE = 20;
  const encryptId = (id) => encryptParamID(id);

  const metricsFilter =
    useSelector((state) => state?.metrics?.metricsFilter) || {};
  const selectedRow = useSelector((state) => state?.metrics?.selectedTableRow);
  const [reservationID, setReservationID] = useState("N/A")

  useEffect(() => {
    if (metricList) {
      setReservationID(metricList[0]?.assgnNum)
    }

  }, [metricList])
  const [entityName] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    metricsSearch(
      "/AssignmentMetricSearch",
      { ...metricsFilter, page: Number(currentPage), limit: ITEMS_PER_PAGE },
      (response) => {
        if (!response?.data?.status) {
          dispatch(setmetricsList([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setmetricsList(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  }, [metricsFilter, currentPage]);

  const keyMapping = {
    customerid: "Customer ID",
    contractorid: "Contractor ID",
    assignmentid: "Assignment ID",
  };

  /**
   * useEffect to scroll to the selectedRow when the metricList changes as side effect
   */

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [metricList]);

  const metricsSearch = async (url, data, callback) => {
    const body = data;
    try {
      setMetricsListLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setMetricsListLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setMetricsListLoading(false);
      dispatch(setmetricsList([]));
    }
  };

  /**
   * method to check if the document has a vertical scrollbar
   */

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  /**
   * Pagination options to be used into ReactPagination component
   */

  const pageChangeOptions = {
    endPointFunction: metricsSearch,
    endpoint: "/AssignmentMetricSearch",
    componentFilter: metricsFilter,
    dispatchAction: setmetricsList,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            {!urlHasParams
              ? "Metrics Management"
              : `Metric List for ${Object.keys(params)
                .slice(0, 1)
                .map(
                  (key) =>
                    `${keyMapping[key] || key}: ${key === "assignmentid" ? reservationID : params[key]
                    } ${entityName}`
                )
                .join(", ")}`}
          </p>
        </div>
        {urlHasParams && (
          <div>
            <Link
              onClick={goBack}
              className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
            >
              Back
            </Link>
          </div>
        )}
      </div>
      <div className="relative">
        {!urlHasParams && <MetricsFilter />}
        <div className="card bg-base-100 shadow-md p-6">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Assignment ID</th>
                  <th>Reservation ID</th>
                  <th>Claim Number</th>
                  <th>Customer</th>
                  <th>Contractor</th>
                  <th>Reservation Date</th>
                  <th>Assign Code</th>
                  <th>Metrics Entered from Intime</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody className="text-sm relative">
                {metricsListLoading &&
                  Array.from({ length: 5 }).map((_) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {!metricsListLoading &&
                  Array.isArray(metricList) &&
                  metricList?.length !== 0 &&
                  metricList?.map((data, index) => (
                    <tr
                      key={nanoid()}
                      id={data.reservationsAssignmentsID}
                      className={`table-row-align-top ${data?.reservationsAssignmentsID === selectedRow
                          ? "activeTableRow"
                          : ""
                        }`}
                      ref={(ele) => (rowRefs.current[index] = ele)}
                      onClick={() => {
                        dispatch(
                          setSelectedRow(data?.reservationsAssignmentsID)
                        );
                      }}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                      <td className=" capitalize">{data?.assgnNum || "N/A"}</td>

                      <td className=" capitalize">
                        {data?.reservationid || "N/A"}
                      </td>
                      <td className=" capitalize">
                        {data?.claimnumber || "N/A"}
                      </td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <span
                              className="tooltip text-left"
                              data-tip="View Details"
                            >
                              <button
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                                onClick={() => {
                                  if (data?.customerName !== undefined) {
                                    !data?.isDelete &&
                                      navigate(
                                        `/customer-management/customer-details/${encryptId(
                                          data?.customerid
                                        )}`
                                      );
                                  }
                                }}
                              >
                                {data?.customerFL || "N/A"}
                              </button>
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div
                            className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                          >
                            <button
                              className="text-left"
                              onClick={() => {
                                navigate(
                                  `/contractor-management/contractor-details/${encryptId(
                                    data?.contractorid
                                  )}`
                                );
                              }}
                            >
                              {data?.contractorCompany ?? "N/A"}
                            </button>
                          </div>
                        </div>
                      </td>

                      <td
                        className={`${!data?.reservationDate && "text-center"
                          } capitalize`}
                      >
                        {formatDate(data?.reservationDate)}
                      </td>

                      <td>{data?.assgncode || "N/A"}</td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={data?.metricsEnteredFlag !== 0}
                          readOnly
                        />
                      </td>

                      <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          <button title="">
                            <span className="flex items-center space-x-4">
                              <span
                                className="tooltip text-left"
                                data-tip="View Details"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/metrics-management/metrics-detail/${encryptId(
                                    data?.reservationsAssignmentsID
                                  )}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!metricsListLoading && metricList?.length === 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">
                        No Metrics found for this search
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {metricList?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                // onPageChange={handlePageChange}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {metricsListLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default MetricsManagement;
