import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assignmentCurrentButtonId: 0,
    isDeadMile: null,
    driverPositionCoordinates: null,
};

export const signalRSlice = createSlice({
  name: "signalR",
  initialState,
  reducers: {
    setAssignmentCurrentButtonId: (state, action) => {
      state.assignmentCurrentButtonId = action.payload;
    },
    resetAssignmentCurrentButtonId: (state) => {
      state.assignmentCurrentButtonId = {...initialState.assignmentCurrentButtonId}; 
    },
    setIsDeadMiles: (state, action) => {
      state.isDeadMile = action.payload;
    },
    setDriverPositionCoordinates: (state, action) => {
      state.driverPositionCoordinates = action.payload;
    }
    
  },

});

export const {
    setAssignmentCurrentButtonId,
    resetAssignmentCurrentButtonId,
    setIsDeadMiles
} = signalRSlice.actions;

export default signalRSlice.reducer;
