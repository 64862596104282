import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import { MdRemoveRedEye } from "react-icons/md";
import decryptParamID from "../../../utils/decryptParamID";
import encryptParamID from "../../../utils/encryptParamID";
import { useDispatch, useSelector } from "react-redux";
import { setSelecteClaimFacilitydRow } from "../../../Redux-Toolkit/ClaimsSlice";
import { formatDate, inactiveFlagClass } from "../../../utils/helpers";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewClaimFacilities = () => {

  const dispatch = useDispatch();
  const { claimantName } = useParams();
  const { claimID } = decryptParamID(useParams());
  const { claimnumber } = useParams();

  const [facilityList, setFacilityList] = useState([]);
  const [facilityDataLoading, setFacilityDataLoading] = useState(false);
  const tableRef = useRef(null);
  const rowRefs = useRef([]);
  const encryptId = (id) => encryptParamID(id);

  const selectedRow = useSelector((state) => state?.claims?.selectedClaimFacilityTableRow);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    facilitySearchList("/ClaimsFacilities", Number(claimID), (response) => {
      if (!response?.data?.status) {
        setFacilityList([]);
      } else if (response?.data?.status) {
        setFacilityList(response?.data?.data);
      }
    });
  }, [claimID]);

  /**
   * useEffect to scroll to the selectedRow when the claimsList changes as side effect
   */
  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && rowRefs.current[selectedRow]) {
      const row = rowRefs.current[selectedRow];
      if (row) {
        row.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [facilityList]);

  const facilitySearchList = async (url, claimID, callback) => {
    const body = {
      claimID: claimID,
    };
    try {
      setFacilityDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setFacilityDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setFacilityDataLoading(false);
      setFacilityList([]);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Claim Facilities</p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="claimID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim ID
            </label>
            <input
              name="claimiD"
              value={claimID === 0 ? "" : claimID}
              id="claimiD"
              type="text"
              disabled
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimNumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim Number
            </label>
            <input
              name="claimNumber"
              value={claimID === 0 ? "" : claimnumber}
              id="claimNumber"
              disabled
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimantName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant Name
            </label>
            <input
              name="claimantName"
              value={claimantName || ""}
              disabled
              id="claimantName"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
        </div>
      </div>
      {
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Facility Name</th>
                  <th>Doctor Name</th>
                  <th>Transport Exp Date</th>
                  <th>Interpreter Exp Date</th>
                  <th>Facility Type</th>
                  <th>Authorized by</th>
                  <th>Facility Phone</th>
                  <th>Status</th>
                  <th>Inactive/Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-sm relative">
                {facilityDataLoading &&
                  Array.from({ length: 5 }).map((_, index) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}

                {!facilityDataLoading &&
                  facilityList?.length !== 0 &&
                  facilityList?.map((data, index) => (
                    <tr
                      key={facilityList?.facilityID || nanoid()}
                      id={facilityList?.facilityID || nanoid()}
                      className={`table-row-align-top ${index === selectedRow ? "activeTableRow" : ""
                        }`}
                      ref={(ele) => (rowRefs.current[index] = ele)}
                      onClick={() => {
                        dispatch(setSelecteClaimFacilitydRow(index));
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{data?.facilityname || "N/A"}</td>
                      <td>{data?.facilityname2 || "N/A"}</td>

                      <td style={{ whiteSpace: "nowrap" }}>
                        {formatDate(data?.transpExpDate)}
                      </td>

                      <td style={{ whiteSpace: "nowrap" }}>
                        {formatDate(data?.interpExpDate)}
                      </td>

                      <td>{data?.facilityType || "N/A"}</td>
                      <td>{data?.authContactFL || "N/A"}</td>
                      <td>{data?.phone || "N/A"}</td>
                      <td>{data?.clfstStatus || "N/A"}</td>
                      <td>
                        <label
                          htmlFor="my-modal-5"
                          className={`btn capitalize 
                            ${inactiveFlagClass(data)}
                              text-[#000] border-hidden  btn-xs cursor-default`}
                        >
                          {data?.inactiveflag ? "Inactive" : "Active"}
                        </label>
                      </td>
                      <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          <button title="">
                            <span className="flex items-center space-x-4">
                              <span
                                className="tooltip text-left tooltip-left"
                                data-tip="View Details"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/claims-management/claim-facility-details/${encryptId(claimID)}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!facilityDataLoading && facilityList?.length === 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">
                        No Facilities Found
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
      {facilityDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ViewClaimFacilities;
