import React, { useState, useRef, useEffect } from "react";
import { resetCustomerFilters } from "../../Redux-Toolkit/CustomerSlice";
import { resetClaimantFilters } from "../../Redux-Toolkit/ClaimantsSlice";
import { resetContractorFilters } from "../../Redux-Toolkit/ContractorSlice";
import { resetAssignmentFilters } from "../../Redux-Toolkit/AssignmentSlice";
import { resetClaimFilters } from "../../Redux-Toolkit/ClaimsSlice";
import { resetReservationFilters } from "../../Redux-Toolkit/ReservationSlice";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sideNavbarOptions } from "../../utils/sideNavarOptions";
import { resetStaffFilters } from "../../Redux-Toolkit/StaffSlice";
import { resetmetricsFilters } from "../../Redux-Toolkit/MatricsSlice";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

const HeaderNavOptions = ({ isMobile = false }) => {

  const dispatch = useDispatch();
  const location = useLocation();

  const userRole = useSelector((state) => state?.auth?.role);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const menuOpen = false;

  const filterOptions = {
    "/customer-management": resetCustomerFilters,
    "/claimant-management": resetClaimantFilters,
    "/contractor-management": resetContractorFilters,
    "/staff-management": resetStaffFilters,
    "/assignments-management": resetAssignmentFilters,
    "/claims-management": resetClaimFilters,
    "/reservations-management": resetReservationFilters,
    "/metrics-management": resetmetricsFilters,
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        setIsDropdownActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!isDropdownActive) {
      setIsDropdownActive(
        sideNavbarOptions?.some((option) =>
          option?.subOptions?.some(
            (subOption) =>
              location.pathname === subOption.to ||
              location.pathname.includes("/claimant-management") ||
              location.pathname.includes("/customer-management") ||
              location.pathname.includes("/contractor-management") ||
              location.pathname.includes("/staff-management")
          )
        )
      );
    }
  }, [location?.pathname, sideNavbarOptions]);

  const isActiveOption = (option) => {
    return option?.subOptions?.some(
      (subOption) =>
        location.pathname === subOption.to ||
        location.pathname.includes("/claimant-management") ||
        location.pathname.includes("/customer-management") ||
        location.pathname.includes("/contractor-management") ||
        location.pathname.includes("/staff-management")
    );
  };

  const renderSubOptions = (subOptions) => {
    return subOptions.map((subOption) =>
      subOption.roleAccess &&
        (subOption.roleAccess.includes("all") ||
          subOption.roleAccess.includes(userRole)) ? (
        <li key={nanoid()}>
          <NavLink
            activeClassName="active-no-underline"
            id="active-no-underline"
            className="hover:text-[#63b0e0]"
            to={subOption.to}
          >
            {subOption.name}
          </NavLink>
        </li>
      ) : null
    );
  };

  const renderOption = (option, index) => {
    const isActive = isActiveOption(option);

    if (option.name === "People") {
      return (
        <div className="relative" key={option?.name + " " + index}>

          <details
            open={dropdownOpen}
            onToggle={() => setDropdownOpen(!dropdownOpen)} // This will update your dropdown state
          >
            <summary
              className={`text-l font-extrabold bg-none border-0 cursor-pointer hover:text-[#63b0e0] ${isActive ? "active" : ""
                } ${!isActive && isDropdownActive ? "text-[#63b0e0]" : ""}`}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsDropdownActive(!isDropdownActive); // Toggle the active state with keyboard
                }
              }}
              aria-expanded={dropdownOpen} // Add aria attributes for accessibility
            >
              {option.name}
            </summary>
            {/* Add content that should be displayed when the dropdown is open */}
          </details>

          {dropdownOpen && (
            <ul
              ref={dropdownRef}
              className="absolute mt-3 p-2 border-[1px] border-[#63b0e0] shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52"
            >
              {renderSubOptions(option?.subOptions)}
            </ul>
          )}
        </div>
      );
    } else if (
      option.roleAccess &&
      (option.roleAccess.includes("all") || option.roleAccess.includes(userRole))
    ) {
      return (
        <div className="hover:text-[#63b0e0] text-mk font-normal" key={index}>
          <NavLink
            to={option.to}
            onClick={() => {
              // Iterate over all filter functions and dispatch each one
              Object.values(filterOptions).forEach((filter) => {
                dispatch(filter());
              });
            }}
            className={`px-[12px] py-[10px] ${location.pathname === option.to ? '' : 'hover:bg-[#63b0e0] hover:text-[#000] hover:rounded-lg'
              }`}
          >
            {option.name}
          </NavLink>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`flex ${isMobile ? "flex-col gap-2" : "justify-between items-center"} ${menuOpen ? "block" : "hidden"} lg:block md:block sm:hidden`}>
      <div className={`${isMobile ? "" : "ml-6 justify-start flex gap-6 flex-wrap"}`}>
        {sideNavbarOptions.map((option, index) => renderOption(option, index))}
      </div>
    </div>
  );
};

// PropTypes validation
HeaderNavOptions.propTypes = {
  isMobile: PropTypes.string
};

export default HeaderNavOptions;