import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { MdRemoveRedEye } from "react-icons/md";
import { postMethodData } from "../../../ApiMethods/Api";
import ClaimsFilter from "./ClaimsFilter/ClaimsFilter";
import { setClaimsList, setSelectedRow } from "../../../Redux-Toolkit/ClaimsSlice";
import { updateReservationFilters } from "../../../Redux-Toolkit/ReservationSlice";
import decryptParamID from "../../../utils/decryptParamID";
import { SafeAllowClick, SafeAllowShow } from "../../../utils/safe";
import encryptParamID from "../../../utils/encryptParamID";
import facility from "../../../assets/JNJ-icons/facilities.svg";
import reservation from "../../../assets/JNJ-icons/reserve.svg";
import approved_contractor from "../../../assets/JNJ-icons/approved.svg";
import { formatDate } from "../../../utils/helpers";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ClaimsManagement = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef();

  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const params = decryptParamID(useParams());
  const urlHasParams = Object.keys(params).length > 0;
  const userRole = useSelector((state) => state?.auth?.role);
  const claimsList = useSelector((state) => state?.claims?.claimsList) || [];

  const [claimsListLoading, setClaimsListLoading] = useState(false);
  const rowRefs = useRef([]);
  const [pageCount, setPageCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);
  const ITEMS_PER_PAGE = 20;
  const encryptId = (id) => encryptParamID(id);

  const claimFilters = useSelector((state) => state?.claims?.filterData) || {};
  const selectedRow = useSelector((state) => state?.claims?.selectedTableRow);

  const [entityName, setEntityName] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  /**
   * UseEffect to get the entity name based on the params from the URL
   * It can be Reservation, Contractor, etc. to know for which entity the assignment is being viewed
   */

  useEffect(() => {
    const fetchEntityNames = async () => {
      for (let key in params) {
        if (keyMapping[key]) {
          let newKey = key.replace("id", "ID");
          const endpoint = endpointMapping[key];
          const body = { [newKey]: Number(params[key]) };
          claimsSearch(endpoint, body, (response) => {
            if (response?.data?.data?.length === 0) {
              setEntityName("N/A");
            } else {
              const property = propertyMapping[key];
              setEntityName(response?.data?.data[0]?.[[property]]);
            }
          });
        }
      }
    };
    fetchEntityNames();
  }, []);

  const endpointMapping = {
    customerid: "/CustomerSelDetail",
    claimantid: "/ClaimantSelDetail",
    contractorid: "/ContractorSelDetail",
  };

  const propertyMapping = {
    customerid: "companyName",
    claimantid: "fullName",
    contractorid: "company",
  };

  const keyMapping = {
    customerid: "Customer ID",
    claimantid: "Claimant ID",
    contractorid: "Contractor ID",
  };

  /**
   * useEffect to scroll to the selectedRow when the claimsList changes as side effect
   */

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [claimsList]);

  useEffect(() => {

    claimsSearch(
      "/ClaimsSearch",
      { ...claimFilters, page: Number(currentPage), limit: ITEMS_PER_PAGE },
      (response) => {
        if (!response?.data?.status) {
          dispatch(setClaimsList([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setClaimsList(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  }, [claimFilters, currentPage]);

  const claimsSearch = async (url, data, callback) => {
    const body = data;
    try {
      setClaimsListLoading(true);
      const response = await postMethodData(url, body);
      setClaimsListLoading(false);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the data list:",
        error
      );
      setClaimsListLoading(false);
      dispatch(setClaimsList([]));
    }
  };

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  /**
   * Pagination options to be used into ReactPagination component
   */

  const pageChangeOptions = {
    endPointFunction: claimsSearch,
    endpoint: "/ClaimsSearch",
    componentFilter: claimFilters,
    dispatchAction: setClaimsList,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            {!urlHasParams
              ? "Claims Management"
              : `Claims List for ${Object.keys(params)
                .map(
                  (key) =>
                    `${keyMapping[key] || key}: ${params[key]
                    } | ${entityName}`
                )
                .join(", ")}`}
          </p>
        </div>
        {urlHasParams && (
          <div>
            <Link
              onClick={goBack}
              className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
            >
              Back
            </Link>
          </div>
        )}
      </div>
      <div className="relative">
        {!urlHasParams && <ClaimsFilter />}
        <div className="card bg-base-100 shadow-md p-6">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Claim ID</th>
                  <th>Claim Number</th>
                  <th>Claimant</th>
                  <th>Company Name</th>
                  <th>Customer</th>
                  <th>Injury Date</th>
                  <th>Injury Type</th>
                  <th>Adjuster</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody className="text-sm relative">
                {claimsListLoading &&
                  Array.from({ length: 5 }).map((_, index) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {!claimsListLoading &&
                  claimsList?.length !== 0 &&
                  claimsList?.map((data, index) => (
                    <tr
                      key={claimsList?.claimid}
                      id={data.claimid}
                      className={`table-row-align-top ${data?.claimid === selectedRow ? "activeTableRow" : ""
                        }`}
                      ref={(ele) => (rowRefs.current[index] = ele)}
                      onClick={() => {
                        dispatch(setSelectedRow(data?.claimid));
                      }}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>

                      <td className=" capitalize">{data?.claimid || "N/A"}</td>
                      <td className=" capitalize">
                        {data?.claimnumber || "N/A"}
                      </td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <span
                              className="tooltip text-left"
                              data-tip="View Details"
                            >
                              <div
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                              >
                                <button
                                  className=" text-left"
                                  onClick={() => {
                                    if (data?.claimantName !== undefined) {
                                      !data?.isDelete &&
                                        navigate(
                                          `/claimant-management/claimant-details/${encryptId(
                                            data?.claimantid
                                          )}`
                                        );
                                    }
                                  }}
                                >
                                  {data?.claimantName || "N/A"}
                                </button>
                              </div>
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <span
                              className="tooltip text-left"
                              data-tip={SafeAllowShow(
                                "View Details",
                                userRole,
                                "A"
                              )}
                            >
                              <div
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                              >
                                <button
                                  className="text-left capitalize"
                                  onClick={() =>
                                    SafeAllowClick(
                                      () => {
                                        if (data?.customerName !== undefined) {
                                          !data?.isDelete &&
                                            navigate(
                                              `/customer-management/customer-details/${encryptId(
                                                data?.customerid
                                              )}`
                                            );
                                        }
                                      },
                                      userRole,
                                      "A"
                                    )
                                  }
                                >
                                  {data?.customerName || "N/A"}
                                </button>
                              </div>
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>{data?.customerFL || "N/A"}</td>
                      <td
                        className={`${!data?.injurydate && "text-center"
                          } capitalize`}
                      >
                        {formatDate(data?.injurydate)}
                      </td>
                      <td>{data?.injurytype || "N/A"}</td>
                      <td>{data?.invoiceContact || "N/A"}</td>

                      <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          <button title="">
                            <span className="flex items-center space-x-4">
                              <span
                                className="tooltip text-left"
                                data-tip="View Details"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/claims-management/claim-details/${encryptId(
                                    data?.claimid
                                  )}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>
                              <span
                                className="tooltip text-left stroke-current fill-current h-4 w-4"
                                data-tip="Claim Facilities"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/claims-management/claim-facilities/${encryptId(
                                    data?.claimid
                                  )}/${data?.claimnumber}/${data?.claimantName}`}
                                >
                                  <img
                                    className="cursor-pointer max-w-none"
                                    alt="Claims"
                                    src={facility}
                                  />
                                </Link>
                              </span>
                              <span
                                className="tooltip text-left tooltip-left"
                                data-tip="Claim Reservations"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/reservations-management/claim/${data?.claimnumber}`}
                                  onClick={() =>
                                    dispatch(
                                      updateReservationFilters({
                                        claimNumber: data?.claimnumber,
                                      })
                                    )
                                  }
                                >
                                  <img
                                    className="cursor-pointer max-w-none"
                                    alt="Claims"
                                    src={reservation}
                                  />
                                </Link>
                              </span>
                              <span
                                className="tooltip tooltip-left text-start"
                                data-tip="Approved Contractors"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/claims-management/claim-approvedContractor/${encryptId(
                                    data?.claimid
                                  )}/${data?.claimnumber}/${data?.claimantName}`}
                                >
                                  <img
                                    className="cursor-pointer max-w-none"
                                    alt="Claims"
                                    src={approved_contractor}
                                  />
                                </Link>
                              </span>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!claimsListLoading && claimsList?.length == 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">No Claim Found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {claimsList?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                // onPageChange={handlePageChange}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {claimsListLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ClaimsManagement;
