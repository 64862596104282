import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { formatDate } from "../../../utils/helpers";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ContractorDriverList = () => {
  const { id } = decryptParamID(useParams());
  const [contractors, setContractors] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [contractorDataLoading, setContractorDataLoading] = useState(true);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    /*get ContractorDriver list */
    setContractorDataLoading(false);
    getData("/ContractorDriver", id, (response) => {

      if (!response?.data?.status) {
        setContractors([]);
      } else if (response?.data?.status) {
        setContractors(response?.data?.data);
        return response;
      }
    });

    /*get contractor company name from api */

    getData("/ContractorSelDetail", id, (response) => {
      if (response?.data?.status === true) {
        setCompanyName(response?.data?.data[0]?.company);
        return response;
      } else {
        setCompanyName("");
      }
    });
  }, []);

  const getData = async (url, id, callback) => {
    try {
      const data = {
        contractorID: id,
      };

      const response = await postMethodData(url, data);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer claimant list:",
        error
      );
    }
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Drivers List for: {id || "N/A"} | {companyName || "N/A"}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th>Driver Name</th>
                <th>Gender</th>
                <th>Date of Birth</th>
                <th>Diver Licence Number</th>
                <th>Driver Licence Type</th>
                <th>Driver Licence Expiration</th>
                <th>Driver Licence State</th>
                <th>SSN</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {contractorDataLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {contractors?.map((data, index) => (
                <tr key={nanoid()}>
                  <td>{index + 1}</td>

                  <td>{data?.driverName || "N/A"}</td>
                  <td className=" capitalize">{data?.gender || "N/A"}</td>

                  <td>{formatDate(data?.birthdate)}</td>
                  <td className=" capitalize">
                    {data?.drivLicNumber || "N/A"}
                  </td>
                  <td>{data?.condlCode || "N/A"}</td>
                  <td>{formatDate(data?.drivLicExp)}</td>
                  <td className="capitalize">{data?.stateName || "N/A"}</td>
                  <td>{data?.ssn || "N/A"}</td>
                </tr>
              ))}

              {!contractorDataLoading && contractors?.length == 0 && (
                <tr>
                  <td colSpan={11} className="my-10 text-center">
                    <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Driver Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {contractorDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ContractorDriverList;
