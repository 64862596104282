import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { MdModeEdit, MdSettings } from "react-icons/md";
import { getMethodData, postMethodData } from "../../../ApiMethods/Api";
import { nanoid } from "nanoid";
import { setSettingsList, setSettings } from "../../../Redux-Toolkit/SettingSlice";
import { toast } from "react-toastify";

const Settings = () => {

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [settingsState, setSettingsState] = useState([]);
  const [settingsDataLoading, setSettingsDataLoading] = useState(true);
  const [filePreviews, setFilePreviews] = useState({});
  const [files, setFiles] = useState({});

  const goBack = useCallback(() => {
    window.history.back();
  }, []);

  const resetFilePreviews = useCallback(() => {
    setFilePreviews({});
    setFiles({});
  }, []);

  const editButtonHandler = useCallback(() => {
    if (!disabled) {
      resetFilePreviews();
    }
    setDisabled((prevDisabled) => !prevDisabled);
  }, [disabled, resetFilePreviews]);

  useEffect(() => {
    fetchSettings("/Settings");
  }, []);

  const fetchSettings = async (url) => {
    try {
      const response = await getMethodData(url);
      const data = response?.data?.data;

      if (!data) {
        throw new Error("Invalid data structure");
      }

      setSettingsState(data);

      const settingsObject = data.reduce((acc, setting) => {
        if (setting?.settingKey && setting.settingValue !== undefined) {
          acc[setting.settingKey] = setting.settingValue;
        }
        return acc;
      }, {});

      dispatch(setSettings(settingsObject));
      dispatch(setSettingsList(data));
    } catch (error) {
      console.error("An error occurred while fetching settings data:", error);
      setSettingsState([]);
    } finally {
      setSettingsDataLoading(false);
    }
  };

  const postSettings = async (url, newFormData) => {
    try {
      const transformedData = Object.entries(newFormData).map(([key, value]) => ({
        key,
        value,
      }));

      const response = await postMethodData(url, transformedData);
      if (response?.data?.status) {
        toast.success(response?.data?.statusMessage);
        fetchSettings("/Settings");
      } else {
        toast.error(response?.data?.statusMessage);
      }
    } catch (error) {
      console.error("An error occurred while posting settings:", error);
    }
  };

  const postImage = async (url, imageData) => {
    const formData = new FormData();
    formData.append("file", imageData);

    try {
      const response = await postMethodData(url, formData);
      if (response?.data?.status) {
        toast.success(response?.data?.statusMessage);
        return response?.data?.data?.imageUrl;
      } else {
        toast.error(response?.data?.statusMessage);
        return "";
      }
    } catch (error) {
      console.error("An error occurred while uploading image:", error);
      return "";
    }
  };

  const handleFileChange = useCallback((e, settingKey) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFilePreviews((prevPreviews) => ({
        ...prevPreviews,
        [settingKey]: fileUrl,
      }));
      setFiles((prevFiles) => ({
        ...prevFiles,
        [settingKey]: file,
      }));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFormData = {};
    const getUpdateInputs = document.getElementsByClassName("settingUpdateInput");

    for (const input of getUpdateInputs) {

      if (input.type === "file" && (files[input.id] || settingsState[1])) {
        if (files[input.id]) {
          const uploadedImageUrl = await postImage("/WebUploadImage", files[input.id]);
          newFormData[input.id] = uploadedImageUrl;
        } else {
          const imagePath = settingsState[1]?.settingValue?.split("/Images/")[1];
          newFormData[input.id] = `/Images/${imagePath}`;
        }
      } else {
        newFormData[input.id] = input.value;
      }
    }

    postSettings("/UpdateSettings", newFormData);

    if (disabled) return;

    editButtonHandler();
  };

  const renderInput = useCallback(
    (data) => {
      switch (data?.inputType) {
        case "file":
          return (
            <div className="grid grid-cols-3 items-center">
              <input
                name={data?.settingKey}
                id={data?.settingKey}
                type={data?.inputType}
                disabled={disabled}
                autoComplete="off"
                className="col-span-2 input settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                onChange={(e) => handleFileChange(e, data?.settingKey)}
              />
              <div className="col-span-1 flex items-center">
                <img
                  className="w-20"
                  src={filePreviews[data?.settingKey] || data?.settingValue}
                  alt={data?.label}
                />
              </div>
            </div>
          );
        case "select":
          return (
            <select
              defaultValue={data?.settingValue}
              required
              disabled={disabled}
              name={data?.settingKey}
              id={data?.settingKey}
              className="select settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered"
            >
              {data?.input_options?.map((res) => (
                <option key={nanoid()}>{res}</option>
              ))}
            </select>
          );
        default:
          return (
            <input
              name={data?.settingKey}
              key={data?.settingKey}
              placeholder={data?.settingLabel}
              id={data?.settingKey}
              defaultValue={data?.settingValue}
              type={data?.inputType}
              autoComplete="off"
              disabled={disabled}
              className="input settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          );
      }
    },
    [disabled, filePreviews, handleFileChange]
  );

  const settingsContent = useMemo(() => {
    if (settingsDataLoading || !settingsState.length) {
      return (
        <div className="flex flex-col items-center justify-center my-20 text-[#6e6e6e]">
          <MdSettings className=" text-[#dedede] text-8xl mx-auto rotate-animation" />
          <p className="font-semibold text-lg">Settings Data Not Found</p>
        </div>
      );
    }

    return (
      <div className="grid lg:grid-cols-3 md:grid-cols-3 md:gap-8 gap-4">
        {settingsState.map((data) => (
          <div key={nanoid()}>
            <label
              htmlFor={data?.settingKey}
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              {data?.settingLabel}
            </label>
            {renderInput(data)}
          </div>
        ))}
      </div>
    );
  }, [settingsDataLoading, settingsState, disabled, filePreviews, renderInput]);

  return (

    <>
      <div className="flex justify-between">
        <div className="mb-2">
          <p className="text-2xl font-semibold">Settings</p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          {settingsContent}
        </div>
        <div className="flex justify-end mt-8 mb-8">
          {!disabled && (
            <button
              type="submit"
              className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000] mr-6"
            >
              Update
            </button>
          )}
          <button
            type="button"
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
            onClick={editButtonHandler}
          >
            {disabled && <MdModeEdit className="mr-1" />}
            {!disabled ? "Cancel" : "Edit"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Settings;