import React, { useState, useEffect } from "react";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { formatDate } from "../../../utils/helpers";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewClaimantDetails = () => {

  const { id } = decryptParamID(useParams());
  const [ claimantDetails, setClaimantDetails] = useState([]);
  const [ claimantDetailsDataLoading, setClaimantDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getDataList("/ClaimantSearch", Number(id));
  }, []);

  /**
   *
   * @param {url} url
   * @returns customers search list
   */

  const getDataList = async (url, id) => {
    const body = {
      claimantID: id,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      inactive: 0,
      page: 0,
      limit: 0,
    };

    try {
      const response = await postMethodData(url, body);
      if (response?.data?.data?.length === 0) {
        setClaimantDetailsDataLoading(false);
        setClaimantDetails([]);
      } else {
        setClaimantDetails(response?.data?.data[0]);
        setClaimantDetailsDataLoading(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setClaimantDetails([]);
    }
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4 capitalize">
          <p className=" text-2xl font-semibold">
            Details for{" "}
            <span className="uppercase">{claimantDetails?.fullName}</span>
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          data-testid="claimant-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="claimantid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claimant ID
              </label>
              <Input
                name="claimantid"
                placeholder="claimant ID"
                disabled
                value={claimantDetails?.claimantID || "N/A"}
                id="claimantid"
                type="text"
                readOnly
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="firstname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name
              </label>
              <Input
                name="firstname"
                placeholder="First Name"
                disabled
                value={claimantDetails?.firstName || "N/A"}
                id="firstname"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Name
              </label>
              <Input
                name="lastname"
                placeholder="Last Name"
                disabled
                value={claimantDetails?.lastName || "N/A"}
                id="lastname"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Email
              </label>
              <Input
                name="email"
                placeholder="Email"
                disabled
                value={
                  claimantDetails?.email ||
                  claimantDetails?.email2 ||
                  claimantDetails?.email3 ||
                  "N/A"
                }
                id="email"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Phone
              </label>
              <Input
                name="phone"
                placeholder="Phone"
                disabled
                value={
                  claimantDetails?.homePhone ||
                  claimantDetails?.cellPhone ||
                  claimantDetails?.homePhonewH ||
                  claimantDetails?.wkPhone ||
                  claimantDetails?.wkPhoneExt ||
                  claimantDetails?.altPhone ||
                  claimantDetails?.hmPhone ||
                  "N/A"
                }
                id="phone"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="dateOfBirth"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Date of Birth
              </label>
              <Input
                name="dateOfBirth"
                placeholder="Date of Birth"
                value={formatDate(claimantDetails?.birthdate)}
                disabled
                id="dateOfBirth"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="gender"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Gender
              </label>
              <Input
                name="gender"
                placeholder="Gender"
                disabled
                value={
                  {
                    M: "Male",
                    F: "Female",
                  }[claimantDetails?.gender] || "N/A"
                }
                id="gender"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="maritalstatus"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Marital Status
              </label>
              <Input
                name="maritalstatus"
                placeholder="maritalstatus"
                disabled
                value={
                  {
                    M: "Married",
                    O: "Other",
                    S: "Single",
                  }[claimantDetails?.clmmsCode] || "N/A"
                }
                id="maritalStatus"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="height"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Height
              </label>
              <Input
                name="height"
                placeholder="Height"
                disabled
                value={
                  claimantDetails?.heightWeight
                    ?.split(";")[0]
                    ?.split(":")[1]
                    ?.trim() || "N/A"
                }
                id="height"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="weight"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Weight
              </label>
              <Input
                name="weight"
                placeholder="Marital Status"
                disabled
                value={
                  claimantDetails?.heightWeight
                    ?.split(";")[1]
                    ?.split(":")[1]
                    ?.trim() || "N/A"
                }
                id="weight"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <Input
                name="status"
                placeholder="Status"
                disabled
                value={claimantDetails?.claimantStatus || "N/A"}
                id="status"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="ssn"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                SSN
              </label>
              <Input
                name="ssn"
                placeholder="SSN"
                disabled
                value={claimantDetails?.ssn || "N/A"}
                id="ssn"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="language"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Language
              </label>
              <Input
                name="language"
                placeholder="Language"
                disabled
                value={
                  claimantDetails?.languCode1 ||
                  claimantDetails?.languCode2 ||
                  "N/A"
                }
                id="language"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Address
              </label>
              <textarea
                name="address"
                placeholder="Address"
                disabled
                value={
                  claimantDetails?.hmaddress1 ||
                  claimantDetails?.hmAddress2 ||
                  "N/A"
                }
                id="address"
                type="text"
                readOnly
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <Input
                name="city"
                placeholder="City"
                disabled
                value={claimantDetails?.hmcity || "N/A"}
                id="city"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code
              </label>
              <Input
                name="zipcode"
                placeholder="Zip Code"
                disabled
                value={claimantDetails?.hmZipCode || "N/A"}
                id="zipcode"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between"></div>
        </form>
        {!claimantDetailsDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewClaimantDetails;
