import React, { useState, useEffect } from "react";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { formatDate } from "../../../utils/helpers";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewClaimDetails = () => {

  const { id } = decryptParamID(useParams());
  const [claimDetails, setClaimDetails] = useState([]);
  const [claimDetailsDataLoading, setClaimDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getDataList("/ClaimsSearch", Number(id));
  }, []);

  /**
   *
   * @param {url} url
   * @returns customers search list
   */

  const getDataList = async (url, id) => {
    const body = {
      claimID: id,
      claimNumber: "",
      customerID: 0,
      claimantID: 0,
      claimantName: "",
      customerName: "",
      birthdate: "",
    };
    try {
      const response = await postMethodData(url, body);
      if (response?.data?.data?.length === 0) {
        setClaimDetails([]);
        setClaimDetailsDataLoading(false);
      } else {
        setClaimDetails(response?.data?.data[0]);
        setClaimDetailsDataLoading(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setClaimDetails([]);
    }
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Details For Claim #{id}</p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          data-testid="claim-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="claimid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claim ID
              </label>
              <Input
                name="claimid"
                value={claimDetails?.claimid || "N/A"}
                id="claimid"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimNumber"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claim Number
              </label>
              <Input
                name="claimNumber"
                value={claimDetails?.claimnumber || "N/A"}
                id="claimNumber"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimantID"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claimant ID
              </label>
              <Input
                name="claimantID"
                value={claimDetails?.claimantid || "N/A"}
                id="claimantID"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimant"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claimant
              </label>
              <Input
                name="claimant"
                value={claimDetails?.claimantName || "N/A"}
                id="claimant"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="customerID"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Customer ID
              </label>
              <Input
                name="customerID"
                value={claimDetails?.customerid || "N/A"}
                id="customerID"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="companyName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Company Name
              </label>
              <Input
                name="companyName"
                value={claimDetails?.customerName || "N/A"}
                id="companyName"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="customerName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Customer Name
              </label>
              <Input
                name="customerName"
                value={claimDetails?.customerFL || "N/A"}
                id="customerName"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimEmployer"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claim Employer
              </label>
              <Input
                name="claimEmployer"
                value={claimDetails?.claimEmployer || "N/A"}
                id="claimEmployer"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="injuryDate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Injury Date
              </label>
              <Input
                name="injuryDate"
                value={formatDate(claimDetails?.injurydate)}
                id="injuryDate"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="injuryType"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Injury Type
              </label>
              <Input
                name="injuryType"
                value={claimDetails?.injurytype || "N/A"}
                id="injuryType"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <Input
                name="status"
                value={claimDetails?.claimStatus || "N/A"}
                id="status"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="vehicleSize"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Vehicle Size
              </label>
              <Input
                name="vehicleSize"
                value={claimDetails?.vehszcode || "N/A"}
                id="vehicleSize"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="transportType"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Transport Type
              </label>
              <Input
                name="transportType"
                value={claimDetails?.trntycode || "N/A"}
                id="transportType"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="claimantHeight"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claimant Height
              </label>
              <Input
                name="claimantHeight"
                value={claimDetails?.height || "N/A"}
                id="claimantHeight"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimantWeight"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claimant Weight
              </label>
              <Input
                name="claimantWeight"
                value={claimDetails?.weight || "N/A"}
                id="claimantWeight"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimantLanguage"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claimant Language
              </label>
              <Input
                name="claimantLanguage"
                value={claimDetails?.languCode || "N/A"}
                id="claimantLanguage"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="adjuster"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Adjuster
              </label>
              <Input
                name="adjuster"
                value={claimDetails?.invoiceContact || "N/A"}
                id="adjuster"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="claimEmployerCity"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claim Employer City
              </label>
              <Input
                name="claimEmployerCity"
                value={claimDetails?.claimEmployerCity || "N/A"}
                id="claimEmployerCity"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between"></div>
        </form>
      </div>
      {!claimDetailsDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ViewClaimDetails;
