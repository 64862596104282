import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { postMethodData } from "../../../ApiMethods/Api";
import encryptParamID from "../../../utils/encryptParamID";
import { updateAssignmentFilters } from "../../../Redux-Toolkit/AssignmentSlice";
import { formatDate } from "../../../utils/helpers";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewReservationDetails = ({ parentComponent }) => {

  const dispatch = useDispatch();
  const { reservationid } = useParams();
  const reservationFilters = useSelector((state) => state?.reservation?.reservationFilters);

  const [reservationDetails, setReservationDetails] = useState({});
  const [serviceCode, setServiceCode] = useState("");
  const [reservationDetailsDataLoading, setReservationDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    reservationSearchList(
      "/ReservationSearch",
      Number(reservationid),
      (response) => {
        if (response?.data?.data?.length === 0) {
          setReservationDetails({});
          setReservationDetailsDataLoading(false);
        } else {
          setReservationDetails(response?.data?.data[0]);
          setServiceCode(response?.data?.data[0]?.rsvsvCode);
          setReservationDetailsDataLoading(true);
        }
      }
    );
  }, []);

  const reservationSearchList = async (url, id, callback) => {
    const body = {
      reservationid: id,
      customerName: reservationFilters?.customerName || "",
      claimantname: reservationFilters?.claimantName || "",
      contractor: reservationFilters?.contractor || "",
      serviceName: reservationFilters?.serviceName || "",
      reservationDate: reservationFilters?.reservationDate || "",
      claimEmployerCity: reservationFilters?.claimEmployerCity || "",
      inactiveflag: reservationFilters?.inactiveflag || "-2",
      page: reservationFilters?.page || 0,
      limit: reservationFilters?.limit || 0,
    };
    try {
      const response = await postMethodData(url, body);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setReservationDetails({});
    }
  };

  return (

    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Reservation Details</p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="reservationId"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Reservation ID
            </label>
            <Input
              name="reservationId"
              value={reservationDetails?.reservationid || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="reservationDate"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Reservation Date
            </label>
            <Input
              name="reservationDate"
              id="reservationDate"
              value={formatDate(reservationDetails?.reservationDate)}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="reservationTime"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Reservation Time
            </label>
            <Input
              name="reservationTime"
              id="reservationTime"
              value={reservationDetails?.reservationTimeF || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="claimid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim ID
            </label>
            <Input
              name="claimid"
              id="claimid"
              value={reservationDetails?.claimid || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="claimNumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim Number
            </label>
            <Input
              name="claimNumber"
              id="claimNumber"
              value={reservationDetails?.claimnumber || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="claimant"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant
            </label>
            <Input
              name="claimant"
              id="claimant"
              value={reservationDetails?.claimantname || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="customer"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Customer
            </label>
            <Input
              name="customer"
              id="customer"
              value={reservationDetails?.customerName || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="facilityName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Facility Name
            </label>
            <Input
              name="facilityName"
              id="facilityName"
              value={reservationDetails?.doFacilityName || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="service"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Service
            </label>
            <Input
              name="service"
              id="service"
              value={reservationDetails?.serviceName || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="status"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Status
            </label>
            <Input
              name="status"
              id="status"
              value={reservationDetails?.rsvaccode || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="pickuplocation"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Pickup Location

            </label>
            <Input
              name="pickupLocation"
              id="pickuplocation"
              value={
                reservationDetails?.puAddress1 &&
                  reservationDetails?.puAddress2 &&
                  reservationDetails?.pucity
                  ? reservationDetails?.puAddress1 +
                  " " +
                  reservationDetails?.puAddress2 +
                  " " +
                  reservationDetails?.pucity
                  : "Not set"
              }
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="pickupZipcode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Pickup Zip Code

            </label>
            <Input
              name="pickupZipcode"
              id="pickupZipcode"
              value={reservationDetails?.puZipCode || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="dropofflocation"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              {serviceCode === "TRNSLT" || serviceCode === "IN" ?
                "Facility Location"
                : "DropOff Location"
              }

            </label>
            <Input
              name="dropoffLocation"
              id="dropofflocation"
              value={reservationDetails?.doAddress1 || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="dropoffzipcode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              {serviceCode === "TRNSLT" || serviceCode === "IN" ?
                "Facility Zip Code"
                : "Dropoff ZipCode"
              }

            </label>
            <Input
              name="dropoffzipcode"
              id="dropoffzipcode"
              value={reservationDetails?.doZipCode || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="transportType"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Transport Type
            </label>
            <Input
              name="transportType"
              id="transportType"
              value={reservationDetails?.transType || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="vehicleSize"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Vehicle Size
            </label>
            <Input
              name="vehicleSize"
              id="vehicleSize"
              value={reservationDetails?.vehicleSize || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="triptype"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Trip Type
            </label>
            <Input
              name="triptype"
              id="triptype"
              value={reservationDetails?.tripType || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
        </div>
      </div>
      {parentComponent !== "assignContractor" && (
        <div className="flex justify-end">
          <Link
            className="btn bg-green hover:bg-green text-[#000] mt-12 mb-8 text-m font-semibold cursor-pointer"
            to={`/assignments-management/reservation/${encryptParamID(reservationDetails?.reservationid)}/${reservationDetails?.claimnumber}/${reservationDetails?.puZipCode}`}
            onClick={() =>
              dispatch(
                updateAssignmentFilters({
                  reservationID: reservationDetails?.reservationid,
                })
              )
            }
          >
            View Assignments
          </Link>
        </div>
      )}

      {/*----------------------------- Cancellation Review ---------------------- */}

      {reservationDetails?.inactiveflag === -1 && (
        <>
          <div className=" p-6 flex justify-start">
            <div>
              <p className=" text-xl font-semibold">
                {" "}
                Cancellation Information{" "}
              </p>
            </div>
          </div>
          <div
            className=" flex card bg-base-100 shadow-md p-6 mt-2"
            style={{ flexDirection: "column" }}
          >
            <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
              <div>
                <label
                  htmlFor="cancelStatus"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Cancel Status
                </label>
                <Input
                  name="cancelStatus"
                  id="cancelStatus"
                  value={reservationDetails?.actionCode || "N/A"}
                  disabled
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </div>
              <div>
                <label
                  htmlFor="cancelReason"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Cancel Reason
                </label>
                <Input
                  name="cancelReason"
                  id="cancelReason"
                  value={reservationDetails?.rsvcXdescription || "N/A"}
                  disabled
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </div>
              <div>
                <label
                  htmlFor="canceledBy"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Canceled By
                </label>
                <Input
                  name="canceledBy"
                  id="canceledBy"
                  value={reservationDetails?.canceledBy || "N/A"}
                  disabled
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </div>
              <div>
                <label
                  htmlFor="cancelDate"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Cancel Date
                </label>
                <Input
                  name="cancelDate"
                  id="cancelDate"
                  value={formatDate(reservationDetails?.canceledDate)}
                  disabled
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </div>
              <div>
                <label
                  htmlFor="cancelTime"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Cancel Time
                </label>
                <Input
                  name="cancelTime"
                  id="cancelTime"
                  value={
                    (reservationDetails?.canceledTime &&
                      moment(reservationDetails?.canceledTime).format("LT")) ||
                    "N/A"
                  }
                  disabled
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </div>
              <div>
                <label
                  htmlFor="cancelConfirm"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Cancel Confirm
                </label>
                <Input
                  name="cancelConfirm"
                  id="cancelConfirm"
                  value={reservationDetails?.cancelConfirm || "N/A"}
                  disabled
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {!reservationDetailsDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

ViewReservationDetails.propTypes = {
  parentComponent: PropTypes.string, // Adjust the type as needed
};

export default ViewReservationDetails;
