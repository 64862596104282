import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Navbar } from "react-daisyui";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { MdAccountCircle, MdLogout, MdOutlineVpnKey, MdSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  setUserRole,
  setuserToken,
  setUserAuthApproved,
  clearAuthToken,
} from "../../Redux-Toolkit/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { postLogin } from "../../ApiMethods/Api";
import { toast } from "react-toastify";
import HeaderNavOptions from "./HeaderNavOptions";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";
import MobileHeader from "./MobileHeader";
import { clearAllIntervals } from "../../utils/setIntervalsManager";
import NotificationIcon from "../Notification/NotificationIcon";
import { resetNotificationsState } from "../../Redux-Toolkit/NotificationSlice";
import Logo from "../../assets/images/JNJ Services-logo-large art-vector.png";

const Header = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [currentShowPassword, setCurrentShowPassword] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmNewShowPassword, setConfirmNewShowPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const userRole = useSelector((state) => state?.auth?.role) || "";
  const userName = useSelector((state) => state?.auth?.userName) || "";

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(null);

  const fcmTokenSent = useSelector((state) => state?.auth?.fcmTokenSent);
  const userID = useSelector((state) => state?.auth?.userID);
  const device_id = useSelector((state) => state?.auth?.device_id);
  const fcmToken = useSelector((state) => state?.auth?.fcmToken);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userEmail = jwtDecode(
    useSelector((state) => state?.auth?.Token)
  )?.email;

  const passwordChange = async () => {
    const body = {
      emailID: userEmail,
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      setPasswordLoading(true);
      const response = await postLogin("/UpdatePassword", body);
      setPasswordLoading(false);
      if (response?.data?.status === false) {
        if (
          response?.data?.statusMessage ===
          "The new password cannot be the same as the old password."
        ) {
          toast.error(
            "The new password must be different from the current password.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else if (
          response?.data?.statusMessage === "Old password is incorrect"
        ) {
          toast.error("Current Password is incorrect.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("User does not exist", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else if (response?.data?.status === true) {
        localStorage.clear();
        dispatch(setUserAuthApproved(false));
        dispatch(setuserToken(""));
        dispatch(setUserRole(""));

        toast.success(
          "Password has been changed. Please login with your new password.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        );

        navigate("/login");
      }
    } catch (error) {
      setPasswordLoading(false);
      console.error("error occured", error);
    }
  };

  const setpasswordpopup = () => {
    setPasswordChangeModal(!passwordChangeModal);
  };

  /**
   * section to update fcmToken once we get it
   */
  useEffect(() => {
    if (!fcmTokenSent) {
      webFcmTokenUpdate();
    }
  }, [fcmToken]);

  const webFcmTokenUpdate = async () => {
    if (!userID || !device_id || !fcmToken) {
      return;
    }
    const body = {
      userID: userID || "",
      userDeviceID: device_id || "",
      userFcmToken: fcmToken || "",
    };
    try {
      const res = await postLogin("/webFcmUpdate", body);
      if (res.status === 200) {
        console.log("FCM token updated successfully");
      } else {
        console.error("Failed to update FCM token");
      }
    } catch (error) {
      console.error("Error updating FCM token:", error);
    }
  };

  /**
   * web fcm token update section end
   */

  const logout = () => {
    dispatch(clearAuthToken());
    dispatch(setUserAuthApproved(false));
    dispatch(setuserToken(""));
    dispatch(setUserRole(""));
    dispatch(resetNotificationsState());

    localStorage.removeItem("isFirstTimeUser");
    localStorage.removeItem("persist:auth");

    /**
     * Clear all intervals
     */
    clearAllIntervals();
    navigate("/login");
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const Reset = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setCurrentShowPassword(false);
    setNewShowPassword(false);
    setConfirmNewShowPassword(false);
  };

  return (

    <div className="mdcal w-full border-b border-[#efefef] bg-[#fff] sticky top-0 right-0 z-[10000] min-h-[120px] max-h-[200px] h-auto pb-[10px]">
      <Navbar className="flex justify-between">
        <button aria-label="Home" onClick={() => navigate("/")}>
          <img className="object-contain w-48 ml-3" alt="Logo" src={Logo} />
        </button>

        <div className="flex flex-col items-end gap-7 mr-7">
          <div className="flex">
            <Dropdown
              ref={dropdownRef}
              vertical="end"
              open={isOpen}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="ghost font-semibold capitalize flex flex-row user-welcome">
                <span className="mr-2 cursor-pointer">{`Welcome, ${userName} (${userRole})`}</span>{" "}
                |{" "}
              </span>
              {isOpen && (
                <Dropdown.Menu className="w-52 menu-compact">
                  <Dropdown.Item
                    onClick={() => {
                      setIsOpen(false);
                      navigate("/profile");
                    }}
                  >
                    <MdAccountCircle />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      setpasswordpopup();
                      setIsOpen(false);
                    }}
                    aria-label="Change Password" // Provide context for accessibility
                  >
                    <MdOutlineVpnKey />
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button" // Render as a button for accessibility
                    onClick={() => {
                      setIsOpen(false);
                      navigate("/settings");
                    }}
                    aria-label="Settings" // Provide context for accessibility
                  >
                    <MdSettings />
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setIsOpen(false);
                      handleLogoutClick();
                    }}
                  >
                    <MdLogout />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
            <button
              className="ml-2 cursor-pointer hover:text-[#63b0e0] md:block hidden"
              onClick={() => {
                setIsOpen(false);
                handleLogoutClick();
              }}
              style={{ flexDirection: "row" }}
            >
              {`Logout`}
            </button>
            <input
              type="checkbox"
              checked={isLogoutModalOpen}
              id="logoutpopup"
              className="modal-toggle"
              onChange={() => setIsLogoutModalOpen(!isLogoutModalOpen)}
            />
            {isLogoutModalOpen && (
              <div className="modal">
                <div className="modal-box relative">
                  <button
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => setIsLogoutModalOpen(false)}
                  >
                    ✕
                  </button>
                  <h3 className="text-lg font-bold text-center">
                    Are you sure you want to logout?
                  </h3>
                  <div className="modal-action justify-center">
                    <button
                      className="btn btn-md bg-[red] hover:bg-[red] text-white text-sm hover:text-white ml-4"
                      onClick={logout}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-md bg-[black] text-[white] text-sm hover:bg-[black]"
                      onClick={() => setIsLogoutModalOpen(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}

            <NotificationIcon />
          </div>
          <HeaderNavOptions />
          {/* <Sidenavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> */}
        </div>
      </Navbar>
      <div className="">
        <MobileHeader />
      </div>
      {/* Password reset/change modal starts from here */}

      <input
        checked={passwordChangeModal}
        type="checkbox"
        id="my-modal-3"
        className="modal-toggle"
        /**
         * call the function without arguments
         */
        onChange={setpasswordpopup}
      />
      <div className="modal">
        <div className="modal-box relative">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <button
              type="button" // Use type="button" to prevent form submission
              onClick={() => {
                setpasswordpopup();
                Reset(); // Call Reset directly when the button is clicked
              }}
              className="btn btn-sm btn-circle absolute right-2 top-2"
              aria-label="Close" // Optional: add aria-label for accessibility
            >
              ✕
            </button>
            <h3 className="text-lg font-bold">Change Your Password</h3>
            <div className="pt-4">
              <div className="form-control">
                <span className="label-text font-semibold label">
                  Your Current Password
                </span>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    id="current-password"
                    autoComplete={false}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                    name="current-password"
                    type={currentShowPassword ? "text" : "password"}
                    placeholder="Current Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <button
                    className="bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() => setCurrentShowPassword(!currentShowPassword)}
                    aria-label={
                      currentShowPassword ? "Hide password" : "Show password"
                    } // Optional: improve accessibility
                  >
                    {currentShowPassword ? (
                      <RxEyeOpen className="text-[#000] ml-3 mr-3" />
                    ) : (
                      <RxEyeClosed className="text-[#000] ml-3 mr-3" />
                    )}
                  </button>
                </label>
              </div>
              <div className="form-control mt-2">
                <span className="label-text font-semibold label">
                  Your New Password
                </span>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    id="new-password"
                    autoComplete={false}
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    required
                    name="new-password"
                    type={newShowPassword ? "text" : "password"}
                    placeholder="New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <button
                    className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() => setNewShowPassword(!newShowPassword)}
                    aria-label={
                      newShowPassword ? "Hide password" : "Show password"
                    }
                  >
                    {newShowPassword ? (
                      <RxEyeOpen className=" text-[#000] ml-3 mr-3" />
                    ) : (
                      <RxEyeClosed className=" text-[#000] ml-3 mr-3" />
                    )}
                  </button>
                </label>
              </div>
              <div className="form-control mt-2">
                <span className="label-text font-semibold">
                  Confirm New Password
                </span>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    id="confirm-password"
                    autoComplete={false}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required
                    name="confirm-password"
                    type={confirmNewShowPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <button
                    className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() =>
                      setConfirmNewShowPassword(!confirmNewShowPassword)
                    }
                    aria-label={
                      confirmNewShowPassword ? "Hide password" : "Show password"
                    }
                  >
                    {confirmNewShowPassword ? (
                      <RxEyeOpen className=" text-[#000] ml-3 mr-3" />
                    ) : (
                      <RxEyeClosed className=" text-[#000] ml-3 mr-3" />
                    )}
                  </button>
                </label>
              </div>
              <PasswordStrengthBar password={newPassword} />
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "lowercase",
                  "match",
                  "nonEmpty",
                ]}
                minLength={8}
                value={newPassword}
                valueAgain={confirmPassword}
                messages={{
                  minLength: "The password must be at least 8 characters long.",
                  specialChar: "The password must include special characters.",
                  number: "The password must contain a number.",
                  capital:
                    "The password must have at least one uppercase letter.",
                  match: "The passwords must match.",
                  lowercase:
                    "The password must have at least one lowercase letter.",
                }}
                onChange={(isValid) => {
                  if (currentPassword !== "" && isValid) {
                    setIsPasswordValid(true);
                  } else {
                    setIsPasswordValid(false);
                  }
                }}
              />
              <div className=" flex justify-center ">
                {!passwordLoading ? (
                  <button
                    type="submit"
                    disabled={!isPasswordValid}
                    onClick={() => passwordChange()}
                    className={`mt-5 btn w-[50%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none`}
                  >
                    Update Password
                  </button>
                ) : (
                  <button
                    type="button"
                    className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-[#63b0e0] hover:text-[#63b0e0] font-semibold border-none flex justify-center items-center"
                  >
                    <svg
                      className="animate-spin mr-1 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Password reset/change modal ends here */}
    </div>
  );
};

export default Header;
