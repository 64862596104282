import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  Polyline,
  DirectionsRenderer,
  Circle,
} from "@react-google-maps/api";
import driver from "../../../assets/images/driver.png";
import { postMethodData } from "../../../ApiMethods/Api";
import { deserializeCoordinates } from "../../../utils/helpers";
import SignalRComponent from "../../../Common/Sockets/SignalR";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const GoogleMapComponent = ({
  lat,
  lng,
  pickupLatLong,
  dropoffLatLong,
  assignmentTrackingData,
  startDriverLatitudeLongitude,
  claimantPickedupLatitudeLongitude,
  index,
}) => {  
  
  const markerRef = useRef(null);
  const [position, setPosition] = useState({ lat: lat || 0, lng: lng || 0 });
  const [markerType, setMarkerType] = useState(null);
  const [locationCoordinates, setLocationCoordinates] = useState([]);
  const mapRef = useRef(null);

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [driverCurrentLocation, setDriverCurrentLocation] = useState(null);
  const [driverCurrentLocationName, setDriverCurrentLocationName] = useState(null);
  const [trackingStartLocation, setTrackingStartLocation] = useState(null);
  const [trackingEndLocation, setTrackingEndLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [updatedDirections, setUpdatedDirections] = useState(null);

  const [driverPosition, setDriverPosition] = useState(null);
  const [tripStartEndLocation, setTripStartEndLocation] = useState([]);
  const [driverPositionCoordinates, setDriverPositionCoordinates] = useState([]);
  const [reservationAssignmentId, setReservationAssignmentId] = useState(null);
  const signalRCurrentButtonId = useSelector((state) => state?.signalR?.assignmentCurrentButtonId);
  const [currentButtonId, setCurrentButtonId] = useState(signalRCurrentButtonId);

  const isDeadMilesRedux = useSelector((state) => state?.signalR?.isDeadMile);

  const [carRotation, setCarRotation] = useState(0);
  const [pickupLocationName, setPickupLocationName] = useState(null);
  const [dropoffLocationName, setDropoffLocationName] = useState(null);

  useEffect(() => {
    if (!GOOGLE_MAPS_API_KEY) {
      console.error("Google Maps API key is missing");
    }
  }, [GOOGLE_MAPS_API_KEY]);

  useEffect(() => {
    setCurrentButtonId(signalRCurrentButtonId);
  }, [signalRCurrentButtonId]);

  // Load the Google Maps API and sets the isLoaded to true or false as per the status
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  //setting the mapRef to the map once the map is loaded
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setIsMapLoaded(true);
  }, []);

  useEffect(() => {
    const driverStartLocationCoordinates = JSON.parse(
      assignmentTrackingData?.startDriverLatitudeLongitude
    );
    const driverStartLatLong = {
      lat: driverStartLocationCoordinates?.latitude,
      lng: driverStartLocationCoordinates?.longitude,
    };

    if (
      !isNaN(lat || driverStartLatLong?.lat) &&
      !isNaN(lng || driverStartLatLong?.lng)
    ) {
      setPosition({
        lat: driverStartLatLong?.lat || lat,
        lng: driverStartLatLong?.lng || lng,
      });
      setDriverPosition({
        lat: driverStartLatLong?.lat || lat,
        lng: driverStartLatLong?.lng || lng,
      });
    }
  }, [lat, lng, assignmentTrackingData]);

  useEffect(() => {
    setPickupLocationName(
      assignmentTrackingData?.puAddress1 +
        " " +
        assignmentTrackingData?.puAddress2 || "N/A"
    );
    setDropoffLocationName(
      assignmentTrackingData?.doAddress1 +
        " " +
        assignmentTrackingData?.doAddress2 || "N/A"
    );

    setLocationCoordinates([]);
    setReservationAssignmentId(
      assignmentTrackingData?.reservationsAssignmentsID
    );
    setCurrentButtonId(assignmentTrackingData?.currentButtonID);

    return () => {
      clearMap();
    };
  }, [assignmentTrackingData]);

  const clearMap = () => {
    if (mapRef.current) {
      mapRef.current = null;
    }
    setLocationCoordinates([]);
    setTripStartEndLocation([]);
    setTrackingStartLocation(null);
    setTrackingEndLocation(null);
    setDirections(null);
  };

  //--------------------- useEffect to use fitBounds to expand map to show all markers locationCoordinates ---------------------//

  //Update the useEffect to create markers and store them in the state:
  useEffect(() => {
    if (
      mapRef.current &&
      locationCoordinates.length > 0 &&
      isMapLoaded &&
      window?.google?.maps
    ) {
      const bounds = new window.google.maps.LatLngBounds();

      locationCoordinates.forEach((location) => {
        if (location.latitude && location.longitude) {
          const latLng = new window.google.maps.LatLng(
            location.latitude,
            location.longitude
          );
          bounds.extend(latLng);
        }
      });

      mapRef.current.fitBounds(bounds);
    }
  }, [
    tripStartEndLocation,
    trackingStartLocation,
    trackingEndLocation,
    isMapLoaded,
    locationCoordinates,
  ]);

  //------------------------ to update the markkers on map ------------------------//
  useEffect(() => {
    if (claimantPickedupLatitudeLongitude || startDriverLatitudeLongitude) {
      setTrackingStartLocation(pickupLatLong);

      setTrackingEndLocation(dropoffLatLong);
      setTripStartEndLocation([pickupLatLong, dropoffLatLong]);
    }
  }, [pickupLatLong, dropoffLatLong, claimantPickedupLatitudeLongitude]);

  //-------------------to pan the map to driver current location when it changes---------//
  useEffect(() => {
    if (
      driverPosition &&
      !isNaN(driverPosition?.lat) &&
      !isNaN(driverPosition?.lng) &&
      mapRef?.current
    ) {
      mapRef?.current?.panTo(driverPosition);
      setPosition(driverPosition);
    }
  }, [driverPosition]);

  //------------------------------------ live tracking blocks below --------------//

  //calling calculateInitialRoute function to get the route between start and end location
  useEffect(() => {
    if (window?.google?.maps) {
      const windowGoogleMapsState = window.google.maps;
      setMap(windowGoogleMapsState);
    }
    if (isLoaded && trackingStartLocation && trackingEndLocation) {
      calculateInitialRoute();
    }
  }, [isLoaded, trackingStartLocation, trackingEndLocation]);

  //function to get the initial direction between start and end location using google maps directions service
  const calculateInitialRoute = async () => {
    if (isLoaded && window?.google?.maps) {
      try {
        const directionsService = new window.google.maps.DirectionsService();
        if (!directionsService) {
          return;
        }
        directionsService.route(
          {
            origin: trackingStartLocation,
            destination: trackingEndLocation,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      } catch (error) {
        console.error("Error calculating route:", error);
      }
    } else {
      console.error("Google Maps API or Maps Service is not available.");
    }
  };

  //api call to get the driver position coordinates using reservationAssignmentId
  const getDriverPositionCoordinates = async () => {
    const body = {
      reservationsAssignmentsID: reservationAssignmentId,
      assignmentTrackingID: assignmentTrackingData?.assignmentTrackingID,
      isDeadMile: index === 0 ? 1 : 0,
    };

    try {
      const response = await postMethodData("/GetLiveCoordinates", body);
      return response?.data?.data;
    } catch (error) {
      console.error("Error getting driver position: ", error);
      throw error;
    }
  };

  //function to calculate bearing between two coordinates
  const calculateBearing = (startLat, startLng, endLat, endLng) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);
    const toDegrees = (radians) => radians * (180 / Math.PI);

    const startLatRad = toRadians(startLat);
    const startLngRad = toRadians(startLng);
    const endLatRad = toRadians(endLat);
    const endLngRad = toRadians(endLng);

    const deltaLng = endLngRad - startLngRad;

    const coordX = Math.sin(deltaLng) * Math.cos(endLatRad);
    const coordY =
      Math.cos(startLatRad) * Math.sin(endLatRad) -
      Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(deltaLng);

    const bearing = toDegrees(Math.atan2(coordX, coordY));
    return (bearing + 360) % 360;
  };

  // Helper function to parse coordinates
  const parseCoordinates = (coordinates) => {
    return coordinates
      .map((item) => {
        const latLng = deserializeCoordinates(item);
        if (!isNaN(latLng.latitude) && !isNaN(latLng.longitude)) {
          return { lat: latLng.latitude, lng: latLng.longitude };
        }
        return null;
      })
      .filter((item) => item !== null);
  };

  // Helper function to handle the driver's position
  const handleDriverPosition = (parsedCoordinates) => {
    if (parsedCoordinates.length > 1) {
      const lastTwoCoordinates = parsedCoordinates.slice(-2);
      const [start, end] = lastTwoCoordinates;
      const bearing = calculateBearing(start.lat, start.lng, end.lat, end.lng);
      setCarRotation(bearing);
    }

    setDriverPosition({
      lat: parsedCoordinates[parsedCoordinates?.length - 1]?.lat,
      lng: parsedCoordinates[parsedCoordinates?.length - 1]?.lng,
    });
  };

  // Main fetch function
  const fetchDriverPosition = async () => {
    if (reservationAssignmentId && currentButtonId <= 4) {
      try {
        const data = await getDriverPositionCoordinates();

        if (data?.googlePath?.length > 0) {
          const parsedTravelPathDirectionCoordinates = parseCoordinates(
            data.googlePath
          );
          setUpdatedDirections(parsedTravelPathDirectionCoordinates);
        }

        if (data?.latitudeLongitude?.length > 0) {
          const parsedCoordinates = parseCoordinates(data.latitudeLongitude);
          setDriverPositionCoordinates(parsedCoordinates);
          handleDriverPosition(parsedCoordinates);
        }
      } catch (error) {
        console.error("Error getting driver position: ", error);
      }
    }
  };

  // useEffect to get the driver position coordinates
  useEffect(() => {
    fetchDriverPosition();
  }, [map, reservationAssignmentId, currentButtonId]);

  //we have dependency in this is [map, reservationAssignmentId, currentButtonId, isDeadMiles]

  useEffect(() => {
    if (window?.google?.maps) {
      console.log("Google Maps API Loaded:", window.google.maps);
    } else {
      console.log("Google Maps API not loaded yet");
    }
  }, [isLoaded]);

  const updateDriverPosition = (driverCoordinatesHistory, newPosition) => {
    if (Array.isArray(newPosition)) {
      setDriverPosition(newPosition[newPosition.length - 1]);
    } else {
      setDriverPosition(newPosition);
    }

    if (Array.isArray(newPosition)) {
      setDriverPosition(newPosition[newPosition.length - 1]);
    } else {
      setDriverPosition(newPosition);
    }

    if (driverCoordinatesHistory?.length > 1 && newPosition) {
      const lastTwoCoordinates = [
        ...driverCoordinatesHistory.slice(-1),
        newPosition,
      ];

      const [start, end] = lastTwoCoordinates;
      const bearing = calculateBearing(start.lat, start.lng, end.lat, end.lng);
      setCarRotation(bearing);
    }
  };

  /**
   *
   * @param {object} locationParam driver position to find its name from using google api
   * @returns {string} string of the location name
   */
  const fetchLocationName = async (locationParam) => {
    if (locationParam) {
      try {
        const location = locationParam;

        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location?.lat},${location?.lng}&key=${GOOGLE_MAPS_API_KEY}`;

        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          if (data.status === "OK" && data.results.length > 0) {
            let formattedAddress = data.results[0].formatted_address;
            // Remove the first part of the address
            formattedAddress = formattedAddress.split(", ").slice(1).join(", ");
            setDriverCurrentLocationName(formattedAddress);
            return formattedAddress;
          } else {
            return "Location name not found";
          }
        } else {
          return "Location name not found";
        }
      } catch (error) {
        console.error(
          "An error occurred while trying to get the location name",
          error
        );
      }
    }
  };

  // Helper functions
  const createGlyphImg = () => {
    const img = document.createElement("img");
    img.src =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/google_logo_g.svg";
    return img;
  };

  const glyphImg = createGlyphImg();

    /**
   * Custom Marker for pickup and dropOff Locations
   */
  const glyphSvgPinElement =
    window?.google?.map &&
    new window.google.map.PinElement({
      glyph: glyphImg,
    });

  // Call the move marker animation
  const animatedMove = (marker, moveFrom, moveTo, t, delta = 100) => {
    const deltalat = calculateDelta(moveFrom.lat(), moveTo.lat(), delta);
    const deltalng = calculateDelta(moveFrom.lng(), moveTo.lng(), delta);
    return animateMarkerMovement(marker, deltalat, deltalng, t, delta);
  };

  const calculateDelta = (from, to, delta) => (to - from) / delta;

  const animateMarkerMovement = (marker, deltalat, deltalng, t, delta) => {
    return new Promise((resolve) => {
      let delay = 10 * t;

      for (let i = 0; i < delta; i++) {
        animateStep(marker, deltalat, deltalng, delay, i, delta, resolve);
      }
    });
  };

  const animateStep = (marker, deltalat, deltalng, delay, index, delta, resolve) => {
    setTimeout(() => {
      let lat = marker?.position.lat();
      let lng = marker?.position.lng();

      lat += deltalat;
      lng += deltalng;

      window?.google?.maps &&
        marker.setPosition(new window.google.maps.LatLng(lat, lng));

      if (index === delta - 1) {
        resolve(marker);
      }
    }, delay * index);
  };

  useEffect(() => {
    if (markerRef.current && driverPosition) {
      const marker = markerRef.current.marker;
      const currentPos = marker.getPosition();
      const newPos =
        window?.google?.maps &&
        new window.google.maps.LatLng(driverPosition.lat, driverPosition.lng);
      animatedMove(marker, currentPos, newPos, 1);
    }
  }, [driverPosition]);

  //-------------------------------------------

  return (
    <>
      {assignmentTrackingData?.reservationsAssignmentsID && (
        <SignalRComponent
          reservationsAssignmentsID={
            assignmentTrackingData?.reservationsAssignmentsID
          }
          setDriverPositionCoordinates={setDriverPositionCoordinates}
          driverPositionCoordinates={driverPositionCoordinates}
          updateDriverPosition={updateDriverPosition}
        />
      )}
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            position: "relative",
            borderRadius: "1rem",
          }}
          zoom={17}
          onLoad={(map) => {
            onMapLoad(map);
            const newCenter = { lat: position.lat, lng: position.lng };
            map?.panTo(newCenter);
          }}
          fullscreenControl={false}
          streetViewControl={false}
          mapTypeControl={false}
        >
          {/* -----------------rendering intial path directions from pickup and dropOff ------------ */}
          {!updatedDirections?.length > 0 && directions && (
            <>
              <DirectionsRenderer
                options={{
                  polylineOptions: {
                    strokeColor: "#808080",
                    strokeWeight: 3,
                  },
                  suppressMarkers: true,
                }}
                directions={directions}
              />
              {/* Custom Marker for Start Location */}
              <Marker
                position={trackingStartLocation}
                icon={glyphSvgPinElement?.element}
                label={{
                  text: "A",
                  color: "white",
                  fontSize: "16px",
                }}
                onMouseOver={() => setMarkerType("Pickup Location")}
                onMouseOut={() => setMarkerType(null)}
              >
                {markerType === "Pickup Location" && (
                  <InfoWindow position={trackingStartLocation}>
                    <div>
                      <div className="font-bold mb-[6px]">Pickup Location</div>
                      <h4>{pickupLocationName}</h4>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
              {/* Custom Marker for End Location */}
              <Marker
                position={trackingEndLocation}
                icon={glyphSvgPinElement?.element}
                label={{
                  text: "B",
                  color: "white",
                  fontSize: "16px",
                }}
                onMouseOver={() => setMarkerType("DropOff Location")}
                onMouseOut={() => setMarkerType(null)}
              >
                {markerType === "DropOff Location" && (
                  <InfoWindow position={trackingEndLocation}>
                    <div>
                      <div className="font-bold mb-[6px]">DropOff Location</div>
                      <h4>{dropoffLocationName}</h4>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </>
          )}

          {/* ------------------ rendering Polyline for updated directions to follow from A to B points --------------- */}
          {updatedDirections && (
            <>
              <Polyline
                options={{
                  zIndex: 1000,
                  strokeColor: "#808080",
                  strokeOpacity: 1.0,
                  strokeWeight: 3,
                  geodesic: true,
                }}
                path={updatedDirections}
              />
              {/* Custom Marker for Start Location */}
              <Marker
                position={trackingStartLocation}
                icon={glyphSvgPinElement?.element}
                label={{
                  text: "A",
                  color: "white",
                  fontSize: "16px",
                }}
                onMouseOver={() => setMarkerType("Pickup Location")}
                onMouseOut={() => setMarkerType(null)}
              >
                {markerType === "Pickup Location" && (
                  <InfoWindow position={trackingStartLocation}>
                    <div>
                      <div className="font-bold mb-[6px]">Pickup Location</div>
                      <h4>{pickupLocationName}</h4>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
              {/* Custom Marker for End Location */}
              <Marker
                position={trackingEndLocation}
                icon={glyphSvgPinElement?.element}
                label={{
                  text: "B",
                  color: "white",
                  fontSize: "16px",
                }}
                onMouseOver={() => setMarkerType("DropOff Location")}
                onMouseOut={() => setMarkerType(null)}
              >
                {markerType === "DropOff Location" && (
                  <InfoWindow position={trackingEndLocation}>
                    <div>
                      <div className="font-bold mb-[6px]">DropOff Location</div>
                      <h4>{dropoffLocationName}</h4>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </>
          )}

          {/* ------------------ rendering Polyline for directions driver travelled --------------- */}
          {driverPositionCoordinates && (
            <Polyline
              options={{
                zIndex: 1000,
                strokeColor: isDeadMilesRedux ? "#047CB6" : "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                geodesic: true,
              }}
              path={driverPositionCoordinates}
            />
          )}

          {/* ------------------ rendering driver current position marker --------------- */}
          {driverPosition &&
            !isNaN(driverPosition.lat) &&
            !isNaN(driverPosition.lng) && (
              <Marker
                position={driverPosition}
                icon={{
                  rotation: carRotation,
                  url: driver,
                  scaledSize:
                    window?.google?.maps &&
                    new window.google.maps.Size(35, 40, "px", "px"),
                  anchor:
                    window?.google?.maps &&
                    new window.google.maps.Point(20, 20),
                }}
                onClick={() => {
                  fetchLocationName(driverPosition);
                  setDriverCurrentLocation(driverPosition);
                  setMarkerType("Current Location");
                }}
                zIndex={1000}
                zoom={30}
                ref={markerRef}
              />
            )}

          {/* ------------------ rendering driver current position Circle --------------- */}
          {driverPosition &&
            !isNaN(driverPosition.lat) &&
            !isNaN(driverPosition.lng) && (
              <Circle
                fillOpacity={0.2}
                options={{
                  fillColor: "#c7e7fc",
                  radius: 10,
                  center: driverPosition,
                  strokeColor: "#4caf50",
                  strokeOpacity: 0.8,
                  strokeWeight: 0.8,
                }}
              />
            )}

          {/* ------------------ info window to show marker name on click of marker --------------- */}
          {(selectedLocation || driverCurrentLocation) && (
            <InfoWindow
              position={{
                lat: driverCurrentLocation?.lat || selectedLocation.lat,
                lng: driverCurrentLocation?.lng || selectedLocation.lng,
              }}
              onCloseClick={() => {
                setSelectedLocation(null);
                setDriverCurrentLocation(null);
                setDriverCurrentLocationName(null);
                setMarkerType(null);
              }}
            >
              <div>
                <div className="font-bold mb-[6px]">{markerType}</div>
                <div>
                  {selectedLocation?.name && <h4>{selectedLocation?.name}</h4>}
                  {driverCurrentLocationName && (
                    <h4>{driverCurrentLocationName}</h4>
                  )}
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

GoogleMapComponent.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  pickupLatLong: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  dropoffLatLong: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  assignmentTrackingData: PropTypes.shape(
    PropTypes.shape({
      startDriverLatitudeLongitude: PropTypes.string,
      puAddress1: PropTypes.string,
      puAddress2: PropTypes.string,
      doAddress1: PropTypes.string,
      doAddress2: PropTypes.string,
      currentButtonID: PropTypes.number,
      assignmentTrackingID: PropTypes.number,
      reservationsAssignmentsID: PropTypes.number,
    })
  ),
  startDriverLatitudeLongitude: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  claimantPickedupLatitudeLongitude: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  index: PropTypes.number,
};

export default GoogleMapComponent;
