import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdAccountCircle, MdAssignmentAdd, MdLogout, MdOutlineVpnKey, MdSettings } from "react-icons/md";
import { FaBars, FaBuilding, FaFileInvoiceDollar, FaPeopleArrows, FaTimes } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setUserRole, setuserToken, setUserAuthApproved } from "../../Redux-Toolkit/AuthSlice";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";
import { RxDashboard, RxEyeClosed, RxEyeOpen, RxPerson } from "react-icons/rx";
import { toast } from "react-toastify";
import { postLogin } from "../../ApiMethods/Api";
import { jwtDecode } from "jwt-decode";
import { Dropdown } from "react-daisyui";
import { sideNavbarOptions } from "../../utils/sideNavarOptions";
import { resetCustomerFilters } from "../../Redux-Toolkit/CustomerSlice";
import { resetClaimantFilters } from "../../Redux-Toolkit/ClaimantsSlice";
import { resetContractorFilters } from "../../Redux-Toolkit/ContractorSlice";
import { resetAssignmentFilters } from "../../Redux-Toolkit/AssignmentSlice";
import { resetClaimFilters } from "../../Redux-Toolkit/ClaimsSlice";
import { resetReservationFilters } from "../../Redux-Toolkit/ReservationSlice";
import { resetStaffFilters } from "../../Redux-Toolkit/StaffSlice";
import { MenuItem, Menu } from "react-pro-sidebar";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { resetmetricsFilters } from "../../Redux-Toolkit/MatricsSlice";

const MobileHeader = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmNewShowPassword, setConfirmNewShowPassword] = useState(false);
  const [currentShowPassword, setCurrentShowPassword] = useState(false);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [isOpen, setIsOpen] = useState(null);

  const userRole = useSelector((state) => state?.auth?.role);
  const userName = useSelector((state) => state?.auth?.userName);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const logout = () => {
    dispatch(setUserAuthApproved(false));
    dispatch(setuserToken(""));
    dispatch(setUserRole(""));
    localStorage.clear();
    navigate("/login");
  };

  const setpasswordpopup = () => {
    setPasswordChangeModal(!passwordChangeModal);
  };

  const passwordChange = async () => {
    const body = {
      emailID: userEmail,
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      setPasswordLoading(true);
      const response = await postLogin("/UpdatePassword", body);
      setPasswordLoading(false);
      if (response?.data?.status === false) {
        toast.error("Invalid Credentials.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (response?.data?.status === true) {
        localStorage.clear();
        dispatch(setUserAuthApproved(false));
        dispatch(setuserToken(""));
        dispatch(setUserRole(""));

        toast.success(
          "Password has been changed. Please login with your new password.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        );
        navigate("/login");
      }
    } catch (error) {
      setPasswordLoading(false);
      console.error("error occured", error);
    }
  };

  const userEmail = jwtDecode(
    useSelector((state) => state?.auth?.Token)
  )?.email;

  const icons = {
    RxDashboard,
    FaBuilding,
    RxPerson,
    FaPeopleArrows,
    MdAssignmentAdd,
    IoIosPeople,
    FaPeopleGroup,
    FaFileInvoiceDollar,
  };

  const events = {
    resetCustomerFilters,
    resetClaimantFilters,
    resetClaimFilters,
    resetReservationFilters,
    resetAssignmentFilters,
    resetContractorFilters,
    resetStaffFilters,
    resetmetricsFilters,
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const Reset = () => {

    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setCurrentShowPassword(false);
    setNewShowPassword(false);
    setConfirmNewShowPassword(false);
  }

  return (

    <div className="bg-white shadow-md p-4 sticky top-0 z-50 mobile-header w-full">
      <div className="flex justify-between items-center w-[60%] ">
        <button onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? (
            <FaTimes className="h-6 w-6" />
          ) : (
            <FaBars className="h-6 w-6" />
          )}
        </button>
      </div>

      {menuOpen && (
        <div className="mt-4">
          <div className="flex flex-col gap-2 bg-[white] text-[black]">
            <Dropdown open={isOpen} onClick={() => setIsOpen(!isOpen)}>
              <span className="ghost font-semibold capitalize flex flex-row">
                <span className="mr-2 cursor-pointer">{`Welcome, ${userName} (${userRole})`}</span>
              </span>

              {isOpen && (
                <Dropdown.Menu className="w-52 menu-compact">
                  <Link to="/profile" onClick={() => setIsOpen(false)}>
                    <Dropdown.Item>
                      <MdAccountCircle />
                      Profile
                    </Dropdown.Item>
                  </Link>
                  <Dropdown.Item
                  as="button"
                  onClick={() => {
                    setpasswordpopup();
                    setIsOpen(false);
                  }}
                  aria-label="Change Password" // Provide context for accessibility
                >
                  <MdOutlineVpnKey />
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  as="button" // Render as a button for accessibility
                  onClick={() => {
                    setIsOpen(false);
                    navigate("/settings");
                  }}
                  aria-label="Settings" // Provide context for accessibility
                >
                  <MdSettings />
                  Settings
                </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setIsOpen(false);
                      handleLogoutClick();
                    }}
                  >
                    <MdLogout />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
            <input
              type="checkbox"
              checked={isLogoutModalOpen}
              id="logout_mobile_header_popup"
              className="modal-toggle"
              onChange={() => setIsLogoutModalOpen(!isLogoutModalOpen)}
            />
            {isLogoutModalOpen && (
              <div className="modal">
                <div className="modal-box relative">
                  <button
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => setIsLogoutModalOpen(false)}
                  >
                    ✕
                  </button>
                  <h3 className="text-lg font-bold text-center">
                    Are you sure you want to logout?
                  </h3>
                  <div className="modal-action justify-center">
                    <button
                      className="btn btn-md bg-[red] hover:bg-[red] text-[#fff] text-sm hover:text-[#fff] ml-4"
                      onClick={logout}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-md bg-[#000] text-[#fff] text-sm hover:bg-[#000]"
                      onClick={() => setIsLogoutModalOpen(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}

            <Menu>
              {sideNavbarOptions.map(
                (option, index) =>
                  option.roleAccess &&
                  (option.roleAccess.includes("all") ||
                    option.roleAccess.includes(userRole)) && (
                    <NavLink
                      key={option?.name}
                      className="link-main"
                      to={option.to}
                      onClick={() =>
                        option.event && dispatch(events[option.event]())
                      }
                    >
                      <MenuItem icon={React.createElement(icons[option.icon])}>
                        {option?.name}
                      </MenuItem>
                    </NavLink>
                  )
              )}
            </Menu>
          </div>
        </div>
      )}

      <input
        readOnly
        checked={passwordChangeModal}
        type="checkbox"
        id="my-modal-3"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box relative">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <button
              type="button" // Use type="button" to prevent form submission
              onClick={() => {
                setpasswordpopup();
                Reset(); // Call Reset directly when the button is clicked
              }}
              className="btn btn-sm btn-circle absolute right-2 top-2"
              aria-label="Close" // Optional: add aria-label for accessibility
            >
              ✕
            </button>
            <h3 className="text-lg font-bold">Change Your Password</h3>
            <div className="pt-4">
              <div className="form-control">
                  <span className="label-text font-semibold label">
                    Your Current Password
                  </span>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                    name="password"
                    type={currentShowPassword ? "text" : "password"}
                    placeholder="Current Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <button
                    className="bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() => setCurrentShowPassword(!currentShowPassword)}
                    aria-label={currentShowPassword ? "Hide password" : "Show password"} // Optional: improve accessibility
                  >
                    {currentShowPassword ? (
                      <RxEyeOpen className="text-[#000] ml-3 mr-3" />
                    ) : (
                      <RxEyeClosed className="text-[#000] ml-3 mr-3" />
                    )}
                  </button>
                </label>
              </div>
              <div className="form-control mt-2">
                  <span className="label-text font-semibold label">
                    Your New Password
                  </span>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    required
                    name="newpassword"
                    type={newShowPassword ? "text" : "password"}
                    placeholder="New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <button
                    className="bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() => setNewShowPassword(!newShowPassword)}
                    aria-label={newShowPassword ? "Hide password" : "Show password"} // Optional: improve accessibility
                  >
                    {newShowPassword ? (
                      <RxEyeOpen className="text-[#000] ml-3 mr-3" />
                    ) : (
                      <RxEyeClosed className="text-[#000] ml-3 mr-3" />
                    )}
                  </button>
                </label>
              </div>
              <div className="form-control mt-2">
                  <span className="label-text font-semibold label">
                    Confirm New Password
                  </span>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required
                    name="confirmpassword"
                    type={confirmNewShowPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <button
                    className="bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() => setConfirmNewShowPassword(!confirmNewShowPassword)}
                    aria-label={confirmNewShowPassword ? "Hide password" : "Show password"} // Optional: improve accessibility
                  >
                    {confirmNewShowPassword ? (
                      <RxEyeOpen className="text-[#000] ml-3 mr-3" />
                    ) : (
                      <RxEyeClosed className="text-[#000] ml-3 mr-3" />
                    )}
                  </button>
                </label>
              </div>
              <PasswordStrengthBar password={newPassword} />
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "lowercase",
                  "match",
                  "nonEmpty",
                ]}
                minLength={8}
                value={newPassword}
                valueAgain={confirmPassword}
                messages={{
                  minLength: "The password must be at least 8 characters long.",
                  specialChar: "The password must include special characters.",
                  number: "The password must contain a number.",
                  capital:
                    "The password must have at least one uppercase letter.",
                  match: "The passwords must match.",
                  lowercase:
                    "The password must have at least one lowercase letter.",
                }}
                onChange={(isValid) => {
                  if (currentPassword !== "" && isValid) {
                    setIsPasswordValid(true);
                  } else {
                    setIsPasswordValid(false);
                  }
                }}
              />

              <div className=" flex justify-center ">
                {!passwordLoading ? (
                  <button
                    type="submit"
                    disabled={!isPasswordValid}
                    onClick={() => passwordChange()}
                    className={`mt-5 btn w-[50%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none`}
                  >
                    Update Password
                  </button>
                ) : (
                  <button
                    type="button"
                    className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-[#63b0e0] hover:text-[#63b0e0] font-semibold border-none flex justify-center items-center"
                  >
                    <svg
                      className="animate-spin mr-1 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
