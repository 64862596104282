import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contractors: [],
  stateList: [],
  serviceList: [],
  statusList: [],
  vehicleSizeList: [],
  languageList: [],
  notificationcount: "",
  selectedTableRow: null,
  approvedContractorSelectedTableRow: null,
  assignContractorSelectedTableRow: null,
  contractorRateTableSelectedTableRow: null,

  contractorFilters: {
    contractorID: 0,
    firstName: "",
    lastName: "",
    mobile: "",
    statusCode: "",
    serviceCode: "",
    zipCode: 0,
    miles: 0,
    inactiveflag: -2,
    vehicleSize: "",
    languageCode: "",
    state: "",
  },
};

export const ContractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setContractor: (state, action) => {
        state.contractors = action.payload;
    },
    setErrLoggingIn: (state, action) => {
      state.errLoggingIn = action.payload;
    },
    // Add other reducers here
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setStatesList: (state, action) => {
      state.stateList = action.payload;
    },
    setServiceList: (state, action) => {
      state.serviceList = action.payload;
    },
    setStatusList: (state, action) => {
      state.statusList = action.payload;
    },
    setVehicleSizeList: (state, action) => {
      state.vehicleSizeList = action.payload;
    },
    setLanguage: (state, action) => {
      state.languageList = action.payload;
    },
    setContractorFilters: (state, action) => {
      state.contractorFilters = action.payload;
    },
    resetContractorFilters: (state) => {
      state.contractorFilters = {...initialState.contractorFilters}; 
      state.selectedTableRow = null;
    },
    updateContractorFilters: (state, action) => {
      state.contractorFilters = { ...state.contractorFilters, ...action.payload };
    },
    setZipCodeList: (state, action) => {
      state.zipCodeList = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
    setApprovedContractorSelectedRow: (state, action) => {
      state.approvedContractorSelectedTableRow = action.payload;
    },
    setAssignContractorSelectedRow: (state, action) => {
      state.assignContractorSelectedTableRow = action.payload;
    },
    setContractorRateSelectedRow: (state, action) => {
      state.contractorRateTableSelectedTableRow = action.payload;
    },
  },

});

export const {
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setStatesList,
  setServiceList,
  setStatusList,
  setContractor,
  setContractorFilters,
  updateContractorFilters,
  setCustomers,
  resetContractorFilters,
  setZipCodeList,
  setVehicleSizeList,
  setLanguage,
  setSelectedRow,
  setApprovedContractorSelectedRow,
  setAssignContractorSelectedRow,
  setContractorRateSelectedRow,
} = ContractorSlice.actions;

export default ContractorSlice.reducer;
