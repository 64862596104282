import React, { useState, useEffect } from "react";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewCustomerDetails = () => {

  const { id } = decryptParamID(useParams());
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerDetailsDataLoading, setCustomerDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    customerSearch("/CustomerSearch", Number(id));
  }, []);

  /**
   * function to get thr customer details for the customer id provided
   * @param {string} url
   * @param {Number} id
   */
  const customerSearch = async (url, id) => {
    const body = {
      customerID: id,
      customerName: "",
      email: "",
      states: "",
      category: "",
      inactive: 0,
      page: 0,
      limit: 0,
    };
    try {
      const response = await postMethodData(url, body);
      if (response?.data?.data?.length === 0) {
        setCustomerDetailsDataLoading(false);
        setCustomerDetails([]);
      } 
      else {
        setCustomerDetails(response?.data?.data[0]);
        setCustomerDetailsDataLoading(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setCustomerDetails([]);
    }
  };

  return (

    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4 capitalize">
          <p className=" text-2xl font-semibold">
            Details for{" "}
            <span className="uppercase">{customerDetails?.companyName}</span>
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
        data-testid="customer-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="customerID"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Customer ID
              </label>
              <Input
                name="customerID"
                value={customerDetails?.customerID || "N/A"}
                id="customerID"
                type="text"
                disabled
                cursor="pointer"
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="firstname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name
              </label>
              <Input
                name="firstname"
                value={customerDetails?.firstname || "N/A"}
                disabled
                id="firstname"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Name
              </label>
              <Input
                name="lastname"
                disabled
                value={customerDetails?.lastname || "N/A"}
                id="lastname"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="company"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Company
              </label>
              <Input
                name="company"
                value={customerDetails?.companyName || "N/A"}
                id="company"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Email
              </label>
              <Input
                name="email"
                value={customerDetails?.email || "N/A"}
                id="email"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="mailingname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Mailing Name
              </label>
              <Input
                name="mailingname"
                value={customerDetails?.mailingName || "N/A"}
                id="mailingname"
                type="text"
                autoComplete="off"
                disabled
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Phone
              </label>
              <Input
                name="phone"
                value={customerDetails?.phone || "N/A"}
                disabled
                id="phone"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="tollfreenumber"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Toll Free Number
              </label>
              <Input
                name="tollfreenumber"
                value={customerDetails?.tollfreePhone || "N/A"}
                disabled
                id="tollfreenumber"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="website"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Website
              </label>
              <Input
                name="website"
                value={customerDetails?.website || "N/A"}
                id="website"
                type="text"
                autoComplete="off"
                disabled
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="category"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Category
              </label>
              <Input
                name="category"
                value={customerDetails?.categoryName || "N/A"}
                id="category"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="leadsource"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Lead Source
              </label>
              <Input
                disabled
                name="leadsource"
                value={customerDetails?.leadSourceName || "N/A"}
                id="leadsource"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Address
              </label>
              <textarea
                name="address"
                disabled
                value={
                  customerDetails?.address1 + " " + customerDetails?.address2 ||
                  "N/A"
                }
                id="address"
                type="text"
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <Input
                name="city"
                disabled
                value={customerDetails?.city || "N/A"}
                id="city"
                type="text"
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code
              </label>
              <Input
                name="zipcode"
                disabled
                value={customerDetails?.zipCode || "N/A"}
                id="zipcode"
                type="text"
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between"></div>
        </form>
        {!customerDetailsDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewCustomerDetails;
