import React from "react";
import { TailSpin } from "react-loader-spinner";

const LoadingPage = () => {

  return (

    <div style={styles.container}>
      <div className="flex justify-center items-center flex-col gap-4 text-[#63b0e0] ">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#63b0e0"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <p>Loading</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  spinner: {
    fontSize: "3rem",
    animation: "spin 1s linear infinite",
  },
};

export default LoadingPage;
